import React, {Component} from 'react';
import styled from 'styled-components';
import UserWizardsList, {
  RocketWizardsList,
  RequestsWizardsList,
} from './UserWizardsList';

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0px;
  top: 0px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  max-height: 100%;
  overflow-y: scroll;
  min-width: 200px;
  margin-bottom: 50px;
`;

const ButtonsContainer = styled.div`
  position: fixed;
  bottom: 0px;
`;
const TopButtonsContainer = styled(ButtonsContainer)`
  bottom: 20px;
`;

class WizardCards extends Component {
  state = {view: 'user'};

  setView = type => {
    this.setState({view: type});
  };

  renderButtons() {
    return (
      <ButtonsContainer>
        {this.state.view !== 'user' ? (
          <button onClick={() => this.setView('user')}>user</button>
        ) : null}
        {this.state.view !== 'rocket' ? (
          <button onClick={() => this.setView('rocket')}>rocket</button>
        ) : null}
        {this.state.view !== 'requests' ? (
          <button onClick={() => this.setView('requests')}>requests</button>
        ) : null}
      </ButtonsContainer>
    );
  }

  render() {
    const {drizzle, drizzleState, children} = this.props;
    const currentAccountAddress = drizzleState.accounts[0];
    const cooAddress =
      drizzleState.contracts.SwissCheezeBank &&
      drizzleState.contracts.SwissCheezeBank.cooAddress['0x0'] &&
      drizzleState.contracts.SwissCheezeBank.cooAddress['0x0'].value;

    if (!cooAddress || cooAddress !== currentAccountAddress) {
      return null;
    }

    return (
      <StyledContainer>
        <TopButtonsContainer>{children}</TopButtonsContainer>
        {
          {
            requests: (
              <RequestsWizardsList
                drizzle={drizzle}
                drizzleState={drizzleState}
              />
            ),
            rocket: (
              <RocketWizardsList
                drizzle={drizzle}
                drizzleState={drizzleState}
              />
            ),
          }[this.state.view]
        }
        {this.renderButtons()}
      </StyledContainer>
    );
  }
}

export default WizardCards;
