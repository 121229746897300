import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {fromWei} from 'ethjs-unit';

import {formatPower, formattedEther, formattedSlice} from '../helpers';
import {StyledWizardPrice as WizardPrice} from './shared';
import Affinity from './Affinity';
import OnboardIcon from './Onboard';
import WizardImgDefault from './WizardImg';

const WizardPower = styled.div`
  bottom: 10px;
  left: 10px;
  font-size: ${({loading}) => (loading ? '12' : '20')}px;
  position: absolute;
  font-family: exocet, 'Roboto Mono', sans-serif;
  font-weight: bold;
  line-height: 100%;
`;

const WizardContainer = styled.div`
  width: 180px;
  height: 223px;
  position: relative;
  margin: 0 25px 25px 0;

  ${({single, hasSeveralWizards}) =>
    single && hasSeveralWizards ? `margin: 10px` : null}

  ${({single}) =>
    single
      ? `
  width: 100%;
  height: 510px;
  position: relative;

  ${WizardImg} {
    position: absolute;
    width: 360px;
    transform: translateX(-50%);
    left: 50%;
  }

  ${WizardId} {
    top: 10px;
    left: 10px;
    font-size: 24px;
  }

  ${WizardStatus} {
    top: 10px;
    right: 10px;
    font-size: 40px;

    svg {
      width: 26px;
      height: 26px;
      vertical-align: baseline;
      position: relative;
      top: 1px;
    }
  }

  ${WizardPower} {
    bottom: 80px;
    left: 10px;
  }

  ${WizardAffinity} {
    bottom: 80px;
    right: 10px;
    font-size: 40px;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  `
      : null}

  ${WizardPower} {
    ${({single, loading}) =>
      single
        ? single && loading
          ? `font-size: 20px;`
          : `font-size: 40px;`
        : null}
  }

  ${({selected}) =>
    selected
      ? `
    box-shadow:inset 0px 0px 0px 1px rgba(161,236,0,.3);
    background-color: rgba(161,236,0,.25);
    box-sizing: border-box;
      `
      : null}

  ${({nopower}) =>
    nopower
      ? `
  ${WizardImg} { opacity: 0.5; };
  ${WizardId} { color: #ff5300 };
  ${WizardStatus} { color: #ff5300 };
  ${WizardPower} { color: #ff5300 };
  svg { fill: #ff5300 };
  `
      : null}
`;

const WizardImg = styled(WizardImgDefault)`
  position: absolute;
  width: 180px;
`;

const WizardId = styled.div`
  top: 10px;
  left: 10px;
  font-size: 12px;
  position: absolute;
  font-family: exocet, 'Roboto Mono', sans-serif;
  font-weight: bold;
  line-height: 100%;
`;

const WizardStatus = styled.div`
  top: 10px;
  right: 10px;
  font-size: 20px;
  position: absolute;
  font-family: exocet, 'Roboto Mono', sans-serif;
  font-weight: bold;
  line-height: 100%;
`;

const WizardAffinity = styled.div`
  bottom: 8px;
  right: 10px;
  font-size: 20px;
  position: absolute;
  font-family: exocet, 'Roboto Mono', sans-serif;
  font-weight: bold;
  line-height: 100%;
  svg {
    width: 18px;
    height: 18px;
    fill: #a1ec00;
  }
`;

const getAffinity = affinity => {
  switch (affinity) {
    case '2':
      return 'fire';
    case '3':
      return 'wind';
    case '4':
      return 'water';
    default:
      return 'neutral';
  }
};

const powerLoadingText = 'loading...';

class WizardCard extends Component {
  state = {dataKey: null};
  componentDidMount() {
    const {drizzle, wizardId} = this.props;
    const contract = drizzle.contracts.BasicTournament;

    // get and save the key for the variable we are interested in
    const dataKey = contract.methods['getWizard'].cacheCall(wizardId);
    this.setState({dataKey});
  }

  componentDidUpdate(prevProps) {
    if (this.props.wizardId !== prevProps.wizardId) {
      const {drizzle, wizardId} = this.props;
      const contract = drizzle.contracts.BasicTournament;

      // get and save the key for the variable we are interested in
      const dataKey = contract.methods['getWizard'].cacheCall(wizardId);
      this.setState({dataKey});
    }
  }

  render() {
    const {
      wizardId,
      wizardImg,
      onClick,
      selected,
      rocketWizard,
      single,
      children,
      onStatusClick,
      tokenPrice,
      hasSeveralWizards,
    } = this.props;
    const {BasicTournament} = this.props.drizzleState.contracts;
    const _wizard = BasicTournament.getWizard[this.state.dataKey];
    const power = (_wizard && _wizard.value && _wizard.value.power) || 0;
    const affinity = _wizard && _wizard.value && _wizard.value.affinity;
    const formattedPower = power
      ? formatPower(_wizard.value.power)
      : powerLoadingText;

    const multiplier = process.env.REACT_APP_UNP === 'true' ? 0.6 : 0.85;

    const price =
      power > 0
        ? single
          ? formattedSlice(
              fromWei(
                parseInt(tokenPrice * parseFloat(formattedPower) * multiplier),
                'ether',
              ),
            )
          : formattedEther(
              fromWei(
                parseInt(tokenPrice * parseFloat(formattedPower) * multiplier),
                'ether',
              ),
            )
        : 0;

    if (_wizard && _wizard.error) {
      return null;
    }

    return (
      <WizardContainer
        onClick={onClick}
        selected={selected}
        nopower={formattedPower === '0'}
        single={single}
        loading={formattedPower === powerLoadingText}
        hasSeveralWizards={hasSeveralWizards}
      >
        <WizardImg src={wizardImg} />
        <WizardId>#{wizardId}</WizardId>
        <WizardStatus onClick={onStatusClick}>
          {(() => {
            if (power > 0) {
              return <WizardPrice>{price}</WizardPrice>;
            } else if (rocketWizard) {
              return <OnboardIcon />;
            } else {
              return 'No power';
            }
          })()}
        </WizardStatus>
        <WizardPower loading={formattedPower === powerLoadingText}>
          {formattedPower}
        </WizardPower>
        <WizardAffinity>
          <Affinity type={getAffinity(affinity)} />
        </WizardAffinity>
        {typeof children === 'function' ? children({price, power}) : children}
      </WizardContainer>
    );
  }
}

const mapStateToProps = state => ({
  tokenPrice: parseFloat(state.cheezxpress.tokenPrice) || 0,
});

export default connect(mapStateToProps)(WizardCard);
