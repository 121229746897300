import React from 'react';
import styled from 'styled-components';
import WizardsGroupContainer from '../containers/WizardsGroupContainer';
import {StyledWizard} from './shared';

const PositionedWizard = styled(StyledWizard)`
    left: ${({left}) => `${left}px`}
    top: ${({top}) => `${top}px`}
    transition: left 300ms, top 300ms
`;

const WizardsGroup = ({
  wizards,
  width = 6,
  offsetX = 60,
  offsetY = 0,
  index,
  tail,
}) => {
  return (
    <WizardsGroupContainer
      wizards={wizards}
      width={width}
      offsetX={offsetX}
      offsetY={offsetY}
      index={index}
      tail={tail}
    >
      {({wizards: _wizards, getWizardImagePath}) =>
        _wizards.map((wizard, index) => (
          <PositionedWizard
            src={getWizardImagePath(wizard.tokenId)}
            left={wizard.left}
            key={index}
            top={wizard.top}
          />
        ))
      }
    </WizardsGroupContainer>
  );
};

export default WizardsGroup;
