import React, {Component} from 'react';
import {DrizzleContext} from '@drizzle/react-plugin';
import {Provider} from 'react-redux';
import ReactModal from 'react-modal';
import styled, {createGlobalStyle} from 'styled-components';
import GetInButton from './components/GetInButton';
import SendButton from './containers/SendButton';
import SetBattleWizardButton from './containers/SetBattleWizardButton';
import EnterWizardsButton from './containers/EnterWizardsButton';
import GreenCorridor from './components/GreenCorridor';
import Statistics from './components/Statistics';
import SocialLinks from './components/SocialLinks';
import GlobalContainer from './containers/GlobalContainer';
import Ship from './components/Ship';
import StyledMoon from './components/Moon';
import WelcomePopup from './components/WelcomePopup';
import WizardCards from './containers/WizardCards';
import WizardsPopup from './containers/WizardsPopup';
import drizzleStore, {drizzle} from './store';
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import Rollbar from 'rollbar';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

  :root {
    --sec-offset: 347px;
    --tail-offset: 656px;
    --tail-offsetY: 173px;
    --tail-innerOffset: 410px;
    --wiz-offset: 45px;
  }

  ::selection {
    background: rgba(161, 236, 0, 0.49);
  }
  body {
    background-size: 1200px 600px;
    padding: 0; margin: 0;
    color: #FFF;
  }
  button {
    background: #FFF;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
`;

const Warning = styled.div`
  color: #000;
  width: 100%;
  background: #d2ac4a;
  position: fixed;
  top: 0px;
  font-family: 'Roboto Mono';
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  height: 30px;
`;

const StyledParallaxContainer = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const rollbarKey =
  process.env.REACT_APP_UNP === 'true'
    ? process.env.REACT_APP_ROLLBAR_KEY_UNP
    : process.env.REACT_APP_ROLLBAR_KEY;

class App extends Component {
  state = {
    showGetInPopup: false,
    showWelcomePopup: !localStorage.getItem('hideWelcomePopup'),

    rollbar: new Rollbar({
      accessToken: rollbarKey,
      captureUncaught: true,
      captureUnhandledRejections: true,
    }),
  };
  handleOpenModal = () => {
    this.setState({showGetInPopup: true});
  };
  handleCloseModal = () => {
    this.setState({showGetInPopup: false});
  };
  componentDidCatch(error, info) {
    this.setState({hasError: true});
  }

  hideWelcomePopup = () => {
    localStorage.setItem('hideWelcomePopup', true);
    this.setState({showWelcomePopup: false});
  };

  render() {
    return (
      <DrizzleContext.Provider drizzle={drizzle}>
        <Provider store={drizzleStore}>
          <GlobalStyle />
          <StyledParallaxContainer>
            <ParallaxProvider scrollAxis="horizontal">
              <div className="stars">
                <div className="twinkling" />
              </div>
              {this.state.hasError ? (
                <h1 style={{position: 'relative'}}>
                  Switch to rinkeby network
                </h1>
              ) : (
                <GlobalContainer>
                  {({
                    wizards,
                    getInButtonRef,
                    wizardPlaceholderRef,
                    greenCorridorRef,
                    greenCorridorVisible,
                    drizzle,
                    drizzleState,
                    onSetBattleWizardButtonClick,
                    onEnterWizardsButtonClick,
                    battleWizard,
                    cooAddress,
                    ceoAddress,
                    currentAccountAddress,
                    blocksToFightWindow,
                    tournamentIsActive,
                  }) => (
                    <div>
                      {drizzle.contracts.BasicTournament && battleWizard ? (
                        <Statistics
                          wizards={wizards}
                          drizzle={drizzle}
                          drizzleState={drizzleState}
                          battleWizard={battleWizard}
                          tournamentIsActive={tournamentIsActive}
                        />
                      ) : null}
                      {tournamentIsActive ? (
                        <GetInButton
                          onClick={this.handleOpenModal}
                          ref={getInButtonRef}
                        />
                      ) : null}
                      <Parallax x={[-20, 20]}>
                        <StyledMoon />
                      </Parallax>
                      {tournamentIsActive ? (
                        <GreenCorridor
                          ref={greenCorridorRef}
                          visible={greenCorridorVisible}
                          sectionsCount={wizards.sections.length}
                        />
                      ) : null}
                      {drizzle && drizzle.contracts.SwissCheezeBank ? (
                        <Ship
                          ref={wizardPlaceholderRef}
                          wizards={wizards}
                          drizzle={drizzle}
                          drizzleState={drizzleState}
                        />
                      ) : null}
                      <WizardCards
                        drizzle={drizzle}
                        drizzleState={drizzleState}
                      >
                        {cooAddress &&
                        cooAddress === currentAccountAddress &&
                        blocksToFightWindow > 0 ? (
                          <div style={{color: '#FFF'}}>
                            {blocksToFightWindow} blocks to wait for fight
                            window
                          </div>
                        ) : null}
                        {cooAddress && cooAddress === currentAccountAddress ? (
                          <SendButton
                            drizzle={drizzle}
                            drizzleState={drizzleState}
                          />
                        ) : null}
                        {cooAddress && cooAddress === currentAccountAddress ? (
                          <SetBattleWizardButton
                            drizzle={drizzle}
                            drizzleState={drizzleState}
                            blocksToFightWindow={blocksToFightWindow}
                          />
                        ) : null}
                        {battleWizard &&
                        cooAddress &&
                        cooAddress === currentAccountAddress ? (
                          <EnterWizardsButton
                            drizzle={drizzle}
                            drizzleState={drizzleState}
                            blocksToFightWindow={blocksToFightWindow}
                          />
                        ) : null}
                      </WizardCards>
                      <ReactModal
                        isOpen={this.state.showGetInPopup}
                        className="popup"
                        overlayClassName="overlay"
                        onRequestClose={this.handleCloseModal}
                      >
                        <WizardsPopup />
                      </ReactModal>
                      <SocialLinks />
                    </div>
                  )}
                </GlobalContainer>
              )}
            </ParallaxProvider>
          </StyledParallaxContainer>
          {this.state.showWelcomePopup ? (
            <WelcomePopup
              drizzle={drizzle}
              onButtonClick={this.hideWelcomePopup}
            />
          ) : null}
          {process.env.REACT_APP_UNP === 'true' ? (
            <Warning>
              WARNING!!! This rocket only accepts unpasteurized cheeze wizards!
            </Warning>
          ) : null}
        </Provider>
      </DrizzleContext.Provider>
    );
  }
}

export default App;
