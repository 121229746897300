import React, {Component} from 'react';
import bn from 'bn.js';
import formatNumber from 'format-number';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {fromWei} from 'ethjs-unit';

import {getContractAddressesForNetworkOrThrow} from '@chzwzrds/contracts-deploy/src/contract_addresses';

import {formattedEther, formattedEtherDet, formattedSlice} from '../helpers';
import {getPoolTokenTotalSupply, getClaimTransactionStatus} from '../reducers';

import {ReactComponent as Slice} from './slice.svg';
import {StyledButton} from './shared';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  text-align: center;
  font-family: 'Roboto Mono';
  width: 100%;

  div:first-child {
    margin-right: 70px;
  }
`;

const Header = styled.header`
  font-size: 16px;
  line-height: 18px;
`;

const Value = styled.div`
  font-size: 32px;
  line-height: 37px;
  color: #a1ec00;
`;

const Button = styled(StyledButton)`
  height: 25px;
  font-size: 16px;
  width: 240px;
  position: absolute;
  right: 50px;
  bottom: 0px;
`;

class PrizeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: null,
    };

    const {WITHDRAWAL} = getContractAddressesForNetworkOrThrow(
      this.props.networkId,
    );

    props.drizzle.web3.eth.getBalance(WITHDRAWAL, (err, balance) => {
      if (err) console.log(err);
      else this.setState({balance});
    });
  }

  componentDidMount() {
    const {drizzle, drizzleState, userAccount} = this.props;
    const contract = drizzle.contracts.Token;

    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );

    // get and save the key for the variable we are interested in
    const balanceDataKey = contract.methods['balanceOf'].cacheCall(userAccount);
    const allowanceDataKey = contract.methods['allowance'].cacheCall(
      userAccount,
      contractAddresses.WITHDRAWAL,
    );
    contract.methods['totalSupply'].cacheCall();
    this.setState({balanceDataKey, allowanceDataKey});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.claimTransactionStatus === 'pending' &&
      this.props.claimTransactionStatus === 'success'
    ) {
      this.sendClaim();
    }
  }

  handleClaimClick = () => {
    const {drizzle, drizzleState, Token} = this.props;
    const {allowanceDataKey, balanceDataKey} = this.state;
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );

    const balance =
      (Token &&
        Token.balanceOf[balanceDataKey] &&
        Token.balanceOf[balanceDataKey].value) ||
      0;
    const allowance =
      (Token &&
        Token.allowance[allowanceDataKey] &&
        Token.allowance[allowanceDataKey].value) ||
      -1;
    const tokensApproved = allowance >= balance;

    if (tokensApproved) {
      this.sendClaim();
    } else {
      if (this.props.drizzle.web3.currentProvider.isDapper) {
        drizzle.contracts.Token.methods
          .approve(contractAddresses.WITHDRAWAL, balance)
          .send({
            from: drizzleState.accounts[0],
          })
          .on('transactionHash', _hash => {
            const timer = setInterval(this.checkApproval, 15000);
            this.setState({
              dapperTimers: {
                ...this.state.dapperTimers,
                timer,
              },
              status: 'approving',
            });
          });
      } else {
        drizzle.contracts.Token.methods.approve.cacheSend(
          contractAddresses.WITHDRAWAL,
          new bn(balance),
          {
            from: drizzleState.accounts[0],
          },
        );
      }
    }
  };

  sendClaim = () => {
    const {drizzle, drizzleState} = this.props;

    if (drizzle.web3.currentProvider.isDapper) {
      drizzle.contracts.Withdrawal.methods
        .claimWinningShare()
        .send({
          from: drizzleState.accounts[0],
        })
        .on('transactionHash', _hash => {
          const timer = setInterval(this.checkClaiming, 15000);
          this.setState({
            dapperTimers: {
              ...this.state.dapperTimers,
              timer,
            },
            status: 'claiming',
          });
        });
    } else {
      drizzle.contracts.Withdrawal.methods.claimWinningShare.cacheSend({
        from: drizzleState.accounts[0],
      });
    }
  };

  checkApproval = () => {
    const {drizzle, drizzleState, userAccount, Token} = this.props;
    const {balanceDataKey} = this.state;
    const balance =
      (Token &&
        Token.balanceOf[balanceDataKey] &&
        Token.balanceOf[balanceDataKey].value) ||
      0;
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );
    this.props.drizzle.contracts.Token.methods
      .allowance(userAccount, contractAddresses.WITHDRAWAL)
      .call()
      .then(allowance => {
        if (allowance >= balance) {
          const dapperTimers = {...this.state.dapperTimers};
          clearInterval(this.state.dapperTimers.timer);
          delete dapperTimers.timer;
          this.setState({dapperTimers});
          this.sendClaim();
        }
      });
  };

  checkClaiming = () => {
    const {drizzle, userAccount} = this.props;
    drizzle.contracts.Token.methods
      .balanceOf(userAccount)
      .call()
      .then(balance => {
        if (balance.toString() === '0') {
          const dapperTimers = {...this.state.dapperTimers};
          clearInterval(this.state.dapperTimers.timer);
          delete dapperTimers.timer;

          const balanceDataKey = drizzle.contracts.Token.methods[
            'balanceOf'
          ].cacheCall(userAccount);
          this.setState({dapperTimers, balanceDataKey});
        }
      });
  };

  render() {
    const {Token, totalSupply, drizzleState, tournamentIsActive} = this.props;
    const {stackId, balanceDataKey, status} = this.state;
    const userTokenBalance =
      (Token &&
        Token.balanceOf[balanceDataKey] &&
        Token.balanceOf[balanceDataKey].value) ||
      '0';

    const userShare =
      totalSupply && totalSupply > 0 ? userTokenBalance / totalSupply : 0;

    let transactionStatus;
    if (drizzleState.transactionStack[stackId]) {
      const txHash = drizzleState.transactionStack[stackId];
      transactionStatus =
        drizzleState.transactions[txHash] &&
        drizzleState.transactions[txHash].status;
    }

    return (
      <Wrapper>
        <div>
          <Header>The Prize:</Header>
          <Value>
            {this.state.balance
              ? `${formattedEther(52)}Ξ`
              : // ? `${formattedEther(fromWei(190828818913857076500, 'ether'))}Ξ`
                'loading...'}
          </Value>
        </div>
        <div>
          <Header>Your Slice</Header>
          <Value>
            <Slice width={19} />
            {formattedEtherDet(fromWei(userTokenBalance, 'ether'))}{' '}
            {formattedEther(fromWei(this.state.balance * userShare, 'ether'))}Ξ
          </Value>
        </div>
        {userShare > 0 ? (
          <Button
            onClick={this.handleClaimClick}
            disabled={status === 'approving' || status === 'claiming'}
          >
            {(() => {
              if (status === 'approving') {
                return 'Approving...';
              } else if (status === 'claiming') {
                return 'Claiming...';
              }
              return 'Claim your tokens';
            })()}
          </Button>
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  networkId: state.web3.networkId,
  userAccount: state.accounts[0],
  Token: state.contracts.Token,
  totalSupply: getPoolTokenTotalSupply(state),
  claimTransactionStatus: getClaimTransactionStatus(state),
});

export default connect(mapStateToProps)(PrizeInfo);
