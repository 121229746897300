import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {DrizzleContext} from '@drizzle/react-plugin';

import {selectWizard} from '../../actions';
import {
  getCurrentUserWizards,
  getRequests,
  getTokenSymbol,
  getWizardImagePath,
} from '../../reducers';
import WizardCard from '../../components/WizardCard';
import SelectedWizardContainer from '../SelectedWizardContainer';

const StyledPopup = styled.div`
  position: fixed;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  width: ${({small}) => (small ? '450px' : '900px')};
  background-color: rgba(0, 0, 0, 0.88);
  border: 1px solid #a1ec00;
  border-radius: 5px;
  z-index: 200;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  font-family: 'Roboto Mono', sans-serif;
  color: #a1ec00;

  * {
    box-sizing: border-box;
  }
`;

const StyledHeader = styled.h1`
  width: 100%;
  /*border: 1px solid red;*/
  margin: 10px 10px 0 10px;
  line-height: 100%;
  font-size: 28px;
`;

const StyledList = styled.div`
  height: 510px;
  margin: 10px;
  position: relative;
  overflow-y: scroll;
  width: calc(50% - 15px);
  margin-left: 10px;
  margin-right: 0;
  padding: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
`;

class WizardsPopup extends Component {
  render() {
    const {
      currentUserWizards,
      getWizardImagePath,
      rocketWizards,
      selectWizard,
      selectedWizard,
    } = this.props;
    return (
      <DrizzleContext.Consumer>
        {drizzleContext => {
          const {drizzle, drizzleState, initialized} = drizzleContext;
          if (!initialized || !drizzle.contracts.BasicTournament) {
            return 'loading...';
          }

          const hasSeveralWizards = currentUserWizards.length > 1;

          return (
            <StyledPopup small={!hasSeveralWizards}>
              <StyledHeader>Your wizards</StyledHeader>
              {hasSeveralWizards ? (
                <StyledList>
                  {currentUserWizards.map(wizardId => (
                    <WizardCard
                      wizardId={wizardId}
                      wizardImg={getWizardImagePath(wizardId)}
                      key={wizardId}
                      drizzle={drizzle}
                      drizzleState={drizzleState}
                      onClick={() => selectWizard(wizardId)}
                      rocketWizard={rocketWizards.includes(wizardId)}
                      selected={
                        !Array.isArray(selectedWizard)
                          ? wizardId === selectedWizard
                          : selectedWizard.includes(wizardId)
                      }
                    />
                  ))}
                </StyledList>
              ) : null}
              {selectedWizard ? (
                <SelectedWizardContainer
                  fullwidth={!hasSeveralWizards}
                  hasSeveralWizards={hasSeveralWizards}
                  drizzle={drizzle}
                  drizzleState={drizzleState}
                />
              ) : (
                <div style={{padding: 10}}>You don't have any wizards</div>
              )}
            </StyledPopup>
          );
        }}
      </DrizzleContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
  currentUserWizards: getCurrentUserWizards(state),
  rocketWizards: getRequests(state.cheezxpress),
  selectedWizard: state.cheezxpress.selectedWizard,
  getWizardImagePath: getWizardImagePath(state),
  tokenSymbol: getTokenSymbol(state),
});

export default connect(
  mapStateToProps,
  {selectWizard},
)(WizardsPopup);
