import React from 'react';
import styled from 'styled-components';
import Onboard from './icon-onboard.svg';

const StyledImg = styled.img`
  width: 32px;
  height: 32px;
  vertical-align: baseline;
  position: relative;
  top: 1px;
`;

const OnboardIcon = () => <StyledImg src={Onboard} alt="is on board" />;

export default OnboardIcon;
