import React from 'react';
import styled from 'styled-components';
import Section from './ShipSection';
import Cabin from './ShipCabin';
import ShipContainer from '../containers/ShipContainer';
import Tail from './ShipTail';

const StyledShip = styled.div`
  position: absolute;
  top: 150px;
  left: 10px;
`;

const StyledSpace = styled.div`
  left: calc(3 * var(--sec-offset) + var(--tail-offset) + 673px);
  width: 10px;
  height: 10px;
`;

const Ship = React.forwardRef(
  (
    {
      wizardsPerSection = 18,
      wizardsInCabin = 11,
      wizardsInTail = 15,
      wizards,
      drizzle,
      drizzleState,
    },
    ref,
  ) => (
    <ShipContainer drizzle={drizzle} drizzleState={drizzleState}>
      {({battleWizard}) => (
        <StyledShip>
          <StyledSpace />
          <Cabin
            wizards={wizards.cabin}
            sectionsLength={wizards.sections.length}
            ref={ref}
            index={0}
            battleWizard={battleWizard}
            id={'section-' + (wizards.sections.length + 1)}
          />
          {wizards.sections.map((group, index) => (
            <Section
              wizards={group}
              index={index + 1}
              sectionsCount={wizards.sections.length}
              key={index}
              id={'section-' + (wizards.sections.length - index)}
            />
          ))}
          <Tail
            wizards={wizards.tail}
            index={wizards.sections.length + 1}
            id={'section-0'}
          />
        </StyledShip>
      )}
    </ShipContainer>
  ),
);

export default Ship;
