export const JOIN_SUCCESS = 'JOIN_SUCCESS';
export const EXPAND_SECTION = 'EXPAND_SECTION';
export const GET_USER_WIZARDS_SUCCESS = 'GET_USER_WIZARDS_SUCCESS';
export const GET_ROCKET_WIZARDS_SUCCESS = 'GET_ROCKET_WIZARDS_SUCCESS';
export const GET_WIZARD_POWER_REQUEST = 'GET_WIZARD_POWER_REQUEST';
export const GET_APPLY_REQUESTS_SUCCESS = 'GET_APPLY_REQUESTS_SUCCESS';
export const SELECT_WIZARD = 'SELECT_WIZARD';
export const SELECT_REQUEST = 'SELECT_REQUEST';
export const GOT_CONTRACT_VAR = 'GOT_CONTRACT_VAR';
export const SEND_CONTRACT_TX = 'SEND_CONTRACT_TX';
export const TX_ERROR = 'TX_ERROR';
export const UPDATE_WIZARD_STATUS = 'UPDATE_WIZARD_STATUS';

export function expandSection(index) {
  return {
    type: EXPAND_SECTION,
    payload: index,
  };
}

export function getUserWizardsSuccess(wizards) {
  return {
    type: GET_USER_WIZARDS_SUCCESS,
    payload: wizards,
  };
}

export function getRocketWizardsSuccess(wizards) {
  return {
    type: GET_ROCKET_WIZARDS_SUCCESS,
    payload: wizards,
  };
}

export function joinSuccess(wizards) {
  return {
    type: JOIN_SUCCESS,
    payload: !Array.isArray(wizards) ? [wizards] : wizards,
  };
}

export function getWizardPowerRequest(wizardId) {
  return {
    type: GET_WIZARD_POWER_REQUEST,
    wizardId,
  };
}

export function selectWizard(wizardId, type = 'normal') {
  if (type === 'requests') {
    return {
      type: SELECT_REQUEST,
      payload: wizardId,
    };
  }
  return {
    type: SELECT_WIZARD,
    payload: wizardId,
  };
}

export function getApplyRequestsSuccess(requests) {
  return {
    type: GET_APPLY_REQUESTS_SUCCESS,
    payload: requests,
  };
}

export function updateWizardStatus(data) {
  return {
    type: UPDATE_WIZARD_STATUS,
    payload: data,
  };
}
