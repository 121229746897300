const drizzleOptions = {
  web3: {
    block: false,
    fallback: {
      type: 'ws',
      url: 'wss://mainnet.infura.io/ws',
    },
  },
  contracts: [],
  polls: {
    accounts: 15000,
    blocks: 15000,
  },
};

export default drizzleOptions;
