import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Slice} from './slice.svg';
import {SECTION_OFFSET, TAIL_OFFSET} from '../constants';
import WizardImg from './WizardImg';

export const StyledWizard = styled(WizardImg)`
  z-index: 1;
  width: calc(1200px / 12.5);
  height: calc(1600px / 12.5);
  position: absolute;
`;

export const StyledSectionContainer = styled.div`
  position: absolute;
  top: var(--tail-offsetY);
  left: ${({index = 1}) => `${(index - 1) * SECTION_OFFSET + TAIL_OFFSET}px`};
`;

export const StyledButton = styled.button`
  height: 40px;
  border-radius: 4px;
  background-color: #9aed00;
  border: none;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 24px;
  outline: inherit;
`;

export const StyledWizardPrice = props => (
  <div>
    <Slice width={15} />
    {props.children}
  </div>
);
