import {Component} from 'react';
import {connect} from 'react-redux';
import {getWizardImagePath} from '../reducers';
import {WIZARD_OFFSET} from '../constants';

class WizardsGroupContainer extends Component {
  render() {
    const {
      offsetX,
      offsetY,
      width,
      expanded,
      tail,
      getWizardImagePath,
    } = this.props;
    const wizards = this.props.wizards.map((wizard, index) => {
      const column = index % width;
      const row = Math.floor(index / width);
      return {
        tokenId: wizard,
        left: expanded
          ? (column * 347) / 5 + (tail ? 356 : 0)
          : offsetX + column * WIZARD_OFFSET + 27 * row,
        top: expanded ? 120 * row + 200 + (tail ? 175 : 0) : 19 * row + offsetY,
      };
    });
    return this.props.children({wizards, getWizardImagePath});
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    expanded:
      ownProps.index !== undefined &&
      ownProps.index === state.cheezxpress.positionedWizards.expandedSection,
    getWizardImagePath: getWizardImagePath(state),
  };
};

export default connect(mapStateToProps)(WizardsGroupContainer);
