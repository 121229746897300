import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import WizardCard from '../components/WizardCard';
import {
  getCurrentUserWizards,
  getRocketWizards,
  getRequests,
  getWizardImagePath,
} from '../reducers';
import {selectWizard} from '../actions';

const StyledUserWizardsList = styled.div`
  z-index: 2;
`;

class WizardsList extends Component {
  render() {
    const {
      userWizards,
      drizzle,
      drizzleState,
      selectWizard,
      selectedWizard,
      getWizardImagePath,
    } = this.props;
    return (
      <StyledUserWizardsList>
        {userWizards.map(wizardId => (
          <WizardCard
            wizardId={wizardId}
            wizardImg={getWizardImagePath(wizardId)}
            key={wizardId}
            drizzle={drizzle}
            drizzleState={drizzleState}
            selected={
              !Array.isArray(selectedWizard)
                ? wizardId === selectedWizard
                : selectedWizard.includes(wizardId)
            }
            onClick={() => selectWizard(wizardId)}
          />
        ))}
      </StyledUserWizardsList>
    );
  }
}

const userMapStateToProps = state => ({
  userWizards: getCurrentUserWizards(state),
  selectedWizard: state.cheezxpress.selectedWizard,
  getWizardImagePath: getWizardImagePath(state),
});

export default connect(
  userMapStateToProps,
  {selectWizard},
)(WizardsList);

const rocketMapStateToProps = state => ({
  userWizards: getRocketWizards(state.cheezxpress),
  selectedWizard: state.cheezxpress.selectedWizard,
  getWizardImagePath: getWizardImagePath(state),
});

export const RocketWizardsList = connect(
  rocketMapStateToProps,
  {selectWizard},
)(WizardsList);

const requestsMapStateToProps = state => ({
  userWizards: getRequests(state.cheezxpress),
  selectedWizard: state.cheezxpress.selectedRequests,
  getWizardImagePath: getWizardImagePath(state),
});

const requestsMapDispatchToProps = dispatch => {
  return {
    selectWizard: wizardId => {
      return dispatch(selectWizard(wizardId, 'requests'));
    },
  };
};

export const RequestsWizardsList = connect(
  requestsMapStateToProps,
  requestsMapDispatchToProps,
)(WizardsList);
