import React, {Component} from 'react';
import {connect} from 'react-redux';
import ControlButton from '../components/ControlButton';

class SetBattleWizardButton extends Component {
  state = {};

  handleButtonClick = () => {
    const {drizzle, drizzleState} = this.props;
    const stackId = drizzle.contracts.SwissCheezeBank.methods.setBattleWizard.cacheSend(
      drizzleState.cheezxpress.selectedWizard,
      false,
      {
        from: drizzleState.accounts[0],
      },
    );

    this.setState({
      stackId,
    });
  };

  render() {
    const {selectedWizard, drizzleState, blocksToFightWindow} = this.props;
    const {stackId} = this.state;
    let transactionStatus;
    if (drizzleState.transactionStack[stackId]) {
      const txHash = drizzleState.transactionStack[stackId];
      transactionStatus =
        drizzleState.transactions[txHash] &&
        drizzleState.transactions[txHash].status;
    }

    return (
      <ControlButton
        onClick={this.handleButtonClick}
        disabled={
          !selectedWizard || transactionStatus === 'pending'
          // blocksToFightWindow != 0
        }
      >
        {transactionStatus === 'pending' ? 'Setting...' : 'Set battle wizard'}
      </ControlButton>
    );
  }
}

const mapStateToProps = state => ({
  selectedWizard:
    state.cheezxpress.selectedWizard &&
    state.cheezxpress.rocketWizards.includes(state.cheezxpress.selectedWizard),
});

export default connect(mapStateToProps)(SetBattleWizardButton);
