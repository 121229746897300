import {connect} from 'react-redux';
import {getWizardImagePath} from '../reducers';
import {expandSection} from '../actions';

const ShipSectionContainer = ({
  sectionOnClick,
  getWizardImagePath,
  children,
}) => {
  return children({sectionOnClick, getWizardImagePath});
};

const mapStateToProps = state => ({
  getWizardImagePath: getWizardImagePath(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sectionOnClick: () => dispatch(expandSection(ownProps.index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShipSectionContainer);
