import React from 'react';
import styled from 'styled-components';
import WizardsGroup from '../WizardsGroup';
import {StyledSectionContainer} from '../shared';
import OnboardLogo from '../OnboardLogo';
import Destination from '../Destination';
import ShipSectionContainer from '../../containers/ShipSectionContainer';
import {ReactComponent as ShipFrontSvg} from './sec-mid-front-right2left.svg';
import {ReactComponent as ShipBackSvg} from './sec-mid-back-right2left.svg';

const ShipFront = styled(ShipFrontSvg)`
  position: absolute;
  z-index: 1;

  #_CabinMidLinear1 {
    ${process.env.REACT_APP_UNP === 'true'
      ? `
    stop:nth-child(1) {
      stop-color: #89621A;
    }
    stop:nth-child(2) {
      stop-color: #7E5713;
    }
    `
      : `
    stop:nth-child(1) {
      stop-color: #78935E;
    }
    stop:nth-child(2) {
      stop-color: #708b57;
    }
    `}
  }
`;

const ShipBack = styled(ShipBackSvg)`
  position: absolute;
  z-index: 0;
`;

export default ({index, wizards, sectionsCount, sectionOnClick, id}) => (
  <ShipSectionContainer index={index}>
    {({sectionOnClick}) => (
      <StyledSectionContainer index={sectionsCount - index + 1}>
        <WizardsGroup wizards={wizards} width={6} index={index} />
        <ShipFront width="436" height="195" onClick={sectionOnClick} id={id} />
        <ShipBack width="436" height="195" />
        {index % 2 ? <Destination /> : <OnboardLogo />}
      </StyledSectionContainer>
    )}
  </ShipSectionContainer>
);
