import React, {Component} from 'react';
import {DrizzleContext} from '@drizzle/react-plugin';
import {connect} from 'react-redux';
import {flow, maxBy} from 'lodash';
import {filter, groupBy, mapValues, values, map, concat} from 'lodash/fp';
import {
  getContractAddressesForNetworkOrThrow,
  getContractGenesisForNetworkOrThrow,
} from '@chzwzrds/contracts-deploy/src/contract_addresses';
import {getBlocksTo} from '@chzwzrds/shared/helpers';
import {FIGHT_WINDOW} from '@chzwzrds/shared/constants';
import {getTransactionStatus} from '../helpers';
import {
  getUserWizardsSuccess,
  getRocketWizardsSuccess,
  getWizardPowerRequest,
  getApplyRequestsSuccess,
  joinSuccess,
} from '../actions';
import {getContractConfig} from '../helpers/contracts';
import StyledLoader from '../components/Loader';

const DrizzleContainer = props => (
  <DrizzleContext.Consumer>
    {drizzleContext => {
      const {drizzle, drizzleState, initialized} = drizzleContext;
      if (!initialized) {
        return <StyledLoader />;
      }

      try {
        return (
          <GlobalContainer
            drizzle={drizzle}
            drizzleState={drizzleState}
            {...props}
          />
        );
      } catch (e) {
        return <div>Something {e}</div>;
      }
    }}
  </DrizzleContext.Consumer>
);

const wizardFlow = (transfers, address) =>
  flow([
    groupBy('returnValues.tokenId'),
    mapValues(ts => maxBy(ts, 'blockNumber')),
    values,
    filter(function(o) {
      return o.returnValues.to === address;
    }),
    map('returnValues.tokenId'),
  ])(transfers);

class GlobalContainer extends Component {
  constructor(props) {
    super(props);

    this.getInButton = React.createRef();
    this.greenCorridor = React.createRef();
    this.wizardPlaceholder = React.createRef();

    const contractGenesis = getContractGenesisForNetworkOrThrow(
      props.drizzleState.web3.networkId,
    );

    const SwissCheezeBank = getContractConfig({
      contractName: 'SwissCheezeBank',
      web3: props.drizzle.web3,
      networkId: props.drizzleState.web3.networkId,
    });

    SwissCheezeBank.web3Contract
      .getPastEvents('ApplyRequest', {
        filter: {txreceipt_status: 1},
        fromBlock: contractGenesis.SWISS_CHEEZE_BANK,
        toBlock: 'latest',
      })
      .then(requests => {
        const _requests = flow([
          groupBy('returnValues.wizardId'),
          mapValues(reqs => maxBy(reqs, 'blockNumber')),
          values,
          map(req => ({
            owner: req.returnValues.owner,
            wizardId: req.returnValues.wizardId,
          })),
        ])(requests);
        this.props.getApplyRequestsSuccess(_requests);
        const _wizards = _requests.map(req => req.wizardId);
        this.props.joinSuccess(_wizards);
      });

    if (!props.drizzle.contracts.SwissCheezeBank)
      props.drizzle.addContract(SwissCheezeBank);

    if (!props.drizzle.contracts.BasicTournament)
      props.drizzle.addContract(
        getContractConfig({
          contractName: 'BasicTournament',
          web3: props.drizzle.web3,
          networkId: props.drizzleState.web3.networkId,
        }),
      );

    if (!props.drizzle.contracts.Withdrawal)
      props.drizzle.addContract(
        getContractConfig({
          contractName: 'Withdrawal',
          web3: props.drizzle.web3,
          networkId: props.drizzleState.web3.networkId,
        }),
      );

    const WizardGuild = getContractConfig({
      contractName: 'WizardGuild',
      web3: props.drizzle.web3,
      networkId: props.drizzleState.web3.networkId,
    });

    const contractAddresses = getContractAddressesForNetworkOrThrow(
      props.drizzleState.web3.networkId,
    );

    const wizardGuildTransfer = filter =>
      WizardGuild.web3Contract.getPastEvents('Transfer', {
        filter: {
          txreceipt_status: 1,
          ...filter,
        },
        fromBlock: contractGenesis.WIZARD_GUILD,
        toBlock: 'latest',
      });

    const getAccountWizards = account =>
      Promise.all([
        wizardGuildTransfer({to: account}),
        wizardGuildTransfer({from: account}),
      ]).then(([transfersToAccount, transfersFromAccount]) => {
        let _userWizardIds = wizardFlow(
          [...transfersToAccount, ...transfersFromAccount],
          account,
        );

        return _userWizardIds;
      });

    getAccountWizards(props.drizzleState.accounts[0]).then(_userWizardIds => {
      this.props.getUserWizardsSuccess(_userWizardIds);
      this.setState({isUserWizardsSuccess: true});
    });

    getAccountWizards(contractAddresses.SWISS_CHEEZE_BANK).then(
      _rocketWizardIds => {
        this.props.getRocketWizardsSuccess(_rocketWizardIds);
        this.setState({isRocketWizardsSuccess: true});
      },
    );

    let events = ['Transfer'];

    if (!props.drizzle.contracts.WizardGuild)
      props.drizzle.addContract(WizardGuild, events);

    const Token = getContractConfig({
      contractName: 'Token',
      web3: props.drizzle.web3,
      networkId: props.drizzleState.web3.networkId,
    });

    if (!props.drizzle.contracts.Token) props.drizzle.addContract(Token);
  }
  state = {
    greenCorridorVisible: false,
  };
  componentDidMount() {
    window.addEventListener('scroll', this.toggle);
    window.addEventListener('resize', this.toggle);
  }

  componentDidUpdate(prevProps) {
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      this.props.drizzleState.web3.networkId,
    );
    if (
      prevProps.wizards.cabin &&
      prevProps.wizards.cabin.length !== this.props.wizards.cabin.length
    ) {
      this.toggle();
    }

    if (
      this.props.drizzle.contracts.SwissCheezeBank &&
      !this.state.ceoAddressKey
    ) {
      this.props.drizzle.contracts.SwissCheezeBank.methods.battleWizard.cacheCall();
      const ceoAddressKey = this.props.drizzle.contracts.SwissCheezeBank.methods.ceoAddress.cacheCall();
      this.props.drizzle.contracts.SwissCheezeBank.methods.cooAddress.cacheCall();
      this.props.drizzle.contracts.SwissCheezeBank.methods.cfoAddress.cacheCall();
      this.props.drizzle.contracts.SwissCheezeBank.methods.getTokenAmount.cacheCall(
        10 ** 12,
      );

      this.setState({ceoAddressKey});
    }

    if (this.props.drizzle.contracts.Token && !this.state.tokenSymbolKey) {
      const tokenSymbolKey = this.props.drizzle.contracts.Token.methods.symbol.cacheCall();
      this.setState({tokenSymbolKey});
    }

    if (
      this.props.drizzle.contracts.BasicTournament &&
      !this.state.tpAddressKey
    ) {
      const tpAddressKey = this.props.drizzle.contracts.BasicTournament.methods.getTimeParameters.cacheCall();
      const isActiveKey = this.props.drizzle.contracts.BasicTournament.methods.isActive.cacheCall();

      this.setState({tpAddressKey, isActiveKey});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggle);
    window.removeEventListener('resize', this.toggle);
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
  }

  getNodes(dx) {
    return (
      'M155.1,240 L135.3,220.2 L' + dx + ',0 L' + (dx + 100) + ',0 L204.7,240 Z'
    );
  }
  toggle = () => {
    if (this.getInButton.current && this.greenCorridor.current) {
      var maxDiff = 240;
      var diff =
        this.getInButton.current.getBoundingClientRect().left -
        this.greenCorridor.current.getBoundingClientRect().left;
      var path = document.getElementById('polygon');

      if (this.state.greenCorridorVisible) {
        if (diff > maxDiff || diff < 0) {
          this.setState({greenCorridorVisible: false});
        } else {
          path.setAttribute('d', this.getNodes(diff));
        }
      } else {
        if (diff <= maxDiff && diff >= 0) {
          path.setAttribute('d', this.getNodes(diff));
          this.setState({greenCorridorVisible: true});
        }
      }
    }
  };

  handleEnterWizardsButtonClick = () => {};

  render() {
    const {wizards, drizzle, drizzleState, currentBlock} = this.props;
    const {
      sendTransactionStackId,
      hasError,
      isRocketWizardsSuccess,
      isUserWizardsSuccess,
    } = this.state;

    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    if (!isRocketWizardsSuccess || !isUserWizardsSuccess) {
      return <StyledLoader />;
    }

    const tournamentTimeParameters =
      drizzleState.contracts.BasicTournament &&
      this.state.tpAddressKey &&
      drizzleState.contracts.BasicTournament.getTimeParameters[
        this.state.tpAddressKey
      ] &&
      drizzleState.contracts.BasicTournament.getTimeParameters[
        this.state.tpAddressKey
      ].value;

    let blocksToFightWindow = -1;

    if (tournamentTimeParameters) {
      const {
        ascensionWindowDuration: ascensionDuration,
        admissionDuration,
        tournamentStartBlock,
        fightWindowDuration: fightDuration,
        duelTimeoutDuration,
        cullingWindowDuration: cullingDuration,
      } = tournamentTimeParameters;

      blocksToFightWindow = getBlocksTo(
        parseInt(tournamentStartBlock),
        FIGHT_WINDOW,
        currentBlock,
        {
          admissionDuration: parseInt(admissionDuration),
          ascensionDuration: parseInt(ascensionDuration),
          fightDuration: parseInt(fightDuration),
          duelTimeoutDuration: parseInt(duelTimeoutDuration),
          cullingDuration: parseInt(cullingDuration),
        },
      );
    }

    const tournamentIsActive =
      drizzleState.contracts.BasicTournament &&
      this.state.isActiveKey &&
      drizzleState.contracts.BasicTournament.isActive[this.state.isActiveKey] &&
      drizzleState.contracts.BasicTournament.isActive[this.state.isActiveKey]
        .value;

    return this.props.children({
      wizards,
      greenCorridorRef: this.greenCorridor,
      getInButtonRef: this.getInButton,
      wizardPlaceholderRef: this.wizardPlaceholder,
      greenCorridorVisible: this.state.greenCorridorVisible,
      drizzle,
      drizzleState,
      onSetBattleWizardButtonClick: this.handleSetBattleWizardButtonClick,
      onEnterWizardsButtonClick: this.handleEnterWizardsButtonClick,
      sendTransactionStatus: getTransactionStatus(
        drizzleState,
        sendTransactionStackId,
      ),
      ceoAddress:
        drizzleState.contracts.SwissCheezeBank &&
        drizzleState.contracts.SwissCheezeBank.ceoAddress['0x0'] &&
        drizzleState.contracts.SwissCheezeBank.ceoAddress['0x0'].value,
      currentAccountAddress: drizzleState.accounts[0],
      cooAddress:
        drizzleState.contracts.SwissCheezeBank &&
        drizzleState.contracts.SwissCheezeBank.cooAddress['0x0'] &&
        drizzleState.contracts.SwissCheezeBank.cooAddress['0x0'].value,
      battleWizard:
        drizzleState.contracts.SwissCheezeBank &&
        drizzleState.contracts.SwissCheezeBank.battleWizard['0x0'] &&
        drizzleState.contracts.SwissCheezeBank.battleWizard['0x0'].value,
      tournamentTimeParameters,
      blocksToFightWindow,
      tournamentIsActive,
    });
  }
}

const mapStateToProps = state => {
  return {
    wizards: state.cheezxpress.positionedWizards,
    currentUserWizards: state.cheezxpress.currentUserWizards,
    currentBlock: state.currentBlock && state.currentBlock.number,
    selectedWizard: state.cheezxpress.selectedWizard,
    transactionStack: state.transactionStack,
    transactions: state.transactions,
  };
};

export default connect(
  mapStateToProps,
  {
    getUserWizardsSuccess,
    getRocketWizardsSuccess,
    getWizardPowerRequest,
    getApplyRequestsSuccess,
    joinSuccess,
  },
  undefined,
  {forwardRef: true},
)(DrizzleContainer);
