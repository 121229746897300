import React from 'react';
import styled from 'styled-components';
import HeadImg from './head.svg';
import TailImg from './tail.svg';
import CenterImg from './center.svg';

const SectionsContainer = styled.div`
  display: flex;
  color: #fff;
  font-size: 10px;
`;

const Section = styled.div`
  margin-right: 5px;
  font-family: 'Roboto Mono';
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;

Section.displayName = 'Section';

const Annotation = styled.div`
  width: 100%;
  padding: 0px 10px;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
`;

const Tail = styled(Section)`
  ${Annotation} {
    width: 100%;
    padding: 0px 10px;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
  }
`;

Tail.displayName = 'Tail';

const Head = styled(Section)`
  ${Annotation} {
    padding: 0px 30px 0px 10px;
  }
`;

Head.displayName = 'Head';

const Img = styled.img`
  display: block;
`;

const scrollIntoView = id => {
  const element = document.getElementById(id);
  element.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
};

const Rocket = ({wizards = {sections: []}}) => {
  const total = 1 + wizards.sections.length + 1;
  const minimumPerBlock = Math.floor(total / 5);
  let extra = total % 5;
  const sectionsCount = Math.min(total, 5);
  const sections = Array.from({length: sectionsCount}, () => minimumPerBlock);
  const sectionsStart = new Array(sectionsCount);
  let currentSection = 0;

  while (extra > 0) {
    sections[currentSection] += 1;
    currentSection++;
    extra--;
  }

  let lastVal = 0;
  let sectionsTexts = sections.map((val, index) => {
    let sectionText = `${lastVal + 1}` + (val > 1 ? `-${lastVal + val}` : '');
    sectionsStart[index] = lastVal;
    lastVal = lastVal + val;
    return sectionText;
  });

  return (
    <SectionsContainer>
      <Tail onClick={() => scrollIntoView(`section-${sectionsStart[0]}`)}>
        <Img src={TailImg} height={54} />
        <Annotation>
          <span>Sectors</span> <span>{sectionsTexts[0]}</span>
        </Annotation>
      </Tail>
      {wizards.sections.slice(0, 3).map((group, index) => (
        <Section
          key={index}
          onClick={() => scrollIntoView(`section-${sectionsStart[index + 1]}`)}
        >
          <Img src={CenterImg} height={26.7} />
          <Annotation>{sectionsTexts[index + 1]}</Annotation>
        </Section>
      ))}
      <Head
        onClick={() =>
          scrollIntoView(`section-${sectionsStart[sectionsTexts.length - 1]}`)
        }
      >
        <Img src={HeadImg} height={26.7} />
        <Annotation>
          <span>{sectionsTexts[sectionsTexts.length - 1]}</span>
        </Annotation>
      </Head>
    </SectionsContainer>
  );
};

export default Rocket;
