import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedPlural} from 'react-intl';
import {formatPower} from '../../helpers';
import PrizeInfo from '../PrizeInfo';
import Rocket from './Rocket';

const StyledStatisticsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 155px - 90px);
  font-size: 10px;
  flex-direction: column;
  margin: 155px auto 0;
  max-width: 825px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  padding-left: 30px;
`;

const StyledNum = styled.div`
  display: block;
  font-size: 24px;
  color: #a1ec00;
`;

const StyledStats = styled.div`
  font-family: 'Roboto Mono';
  display: flex;
  align-items: flex-end;
  align-self: end;
  margin-right: ${({tournamentIsActive}) =>
    tournamentIsActive ? '162px' : '0px'};
`;

const StyledStat = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const TopStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

class Statistics extends Component {
  state = {};

  componentDidMount() {
    const {drizzle, battleWizard} = this.props;
    const contract = drizzle.contracts.BasicTournament;

    // get and save the key for the variable we are interested in
    if (battleWizard !== '0') {
      const dataKey = contract.methods.getWizard.cacheCall(battleWizard);
      this.setState({dataKey});
    }
  }
  render() {
    const {
      battleWizard,
      drizzle,
      drizzleState,
      tournamentIsActive,
      wizards,
      wizardsOnBoard,
    } = this.props;

    const wizardsOnBoardCount =
      wizardsOnBoard.length + (battleWizard > 0 ? 1 : 0);

    const {BasicTournament} = drizzleState.contracts;
    let formattedPower;
    if (BasicTournament) {
      const _wizard = BasicTournament.getWizard[this.state.dataKey];
      const power = _wizard && _wizard.value && _wizard.value.power;
      const molded = _wizard && _wizard.value && _wizard.value.molded;
      formattedPower = molded
        ? '0'
        : power
        ? formatPower(_wizard.value.power)
        : 'loading...';
    }

    return (
      <StyledStatisticsContainer>
        <TopStatistics>
          <Rocket wizards={wizards} />
          <StyledStats tournamentIsActive={tournamentIsActive}>
            <StyledStat>
              <StyledNum>{wizardsOnBoardCount}</StyledNum>
              <FormattedPlural
                value={wizardsOnBoardCount}
                one="wizard"
                other="wizards"
              />
            </StyledStat>
            <StyledStat>
              <StyledNum>{formattedPower}</StyledNum>
              magic power
            </StyledStat>
          </StyledStats>
        </TopStatistics>
        <PrizeInfo
          drizzle={drizzle}
          drizzleState={drizzleState}
          tournamentIsActive={tournamentIsActive}
        />
      </StyledStatisticsContainer>
    );
  }
}

const mapStateToProps = state => ({
  wizardsOnBoard: state.cheezxpress.wizardsOnBoard,
  battleWizard: state.cheezxpress.battleWizard,
});

export default connect(mapStateToProps)(Statistics);
