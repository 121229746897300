import React from 'react';
import styled from 'styled-components';
import {ReactComponent as TailFrontSvg} from './tail-front.svg';
import {ReactComponent as TailBackSvg} from './tail-back.svg';
import WizardsGroup from '../WizardsGroup';
import ShipSectionContainer from '../../containers/ShipSectionContainer';
import {StyledSectionContainer} from '../shared';

const StyledTailContainer = styled(StyledSectionContainer)`
  left: 0px;
  top: 0px;
`;

const TailFront = styled(TailFrontSvg)`
  position: absolute;
  z-index: 1;
  ${process.env.REACT_APP_UNP === 'true'
    ? `
  #front > div {
    fill:#B58C3A;
  }

  #something1 {
    fill:#603D0C;
  }

  #tail-wing-top {
    fill:#D2AC4A;
  }

  #tail-wing-foreground {
    fill:#A0792B;
  }

  #_TailFrontGradient2 {
    stop:first-child {
      stop-color: #EFC458;
    }
    stop:last-child {
      stop-color: #76500E
    }
  }
  `
    : `
  #front > div {
    fill:#9EBC81;
  }
  #something1 {
    fill:#3F5A3a;
  }
  #tail-wing-top {
    fill:#B8D799;
  }
  #tail-wing-foreground {
    fill:#839F68;
  }
  #_TailFrontGradient2 {
    stop:first-child {
      stop-color: #C1E1A1;
    }
    stop:last-child {
      stop-color: #6B8552;
    }
  }

  `}
`;

const TailBack = styled(TailBackSvg)`
  position: absolute;
  z-index: 0;
`;

export default ({wizards, index, id}) => (
  <ShipSectionContainer index={index}>
    {({sectionOnClick}) => (
      <StyledTailContainer>
        <WizardsGroup
          wizards={wizards}
          width={5}
          offsetX={410}
          offsetY={173}
          index={index}
          tail={true}
        />
        <TailFront width="732" height="426" onClick={sectionOnClick} id={id} />
        <TailBack width="732" height="426" />
      </StyledTailContainer>
    )}
  </ShipSectionContainer>
);
