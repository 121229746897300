import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {StyledButton} from './shared';

const StyledContainer = styled.div`
  position: fixed;
  top: 183px;
  max-width: 825px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: end;
  z-index: 1;
`;

const Button = styled(StyledButton)`
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 100px;
`;

const GetInButton = React.forwardRef(({onClick, selectedWizard}, ref) => (
  <StyledContainer>
    <Button onClick={onClick} ref={ref}>
      Get in
    </Button>
  </StyledContainer>
));

const mapStateToProps = state => ({
  selectedWizard: state.cheezxpress.selectedWizard,
});

export default connect(
  mapStateToProps,
  undefined,
  undefined,
  {forwardRef: true},
)(GetInButton);
