import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedPlural} from 'react-intl';
import {fromWei} from 'ethjs-unit';

import {getCurrentUserWizards, getWizardsPower} from '../reducers';
import {formatPower, formattedEther} from '../helpers';
import {Twitter, Telegram} from './SocialLinks/SocialLink';

import PlainLogo from './plain_logo.svg';

const Wrapper = styled.div`
  position: fixed;
  background: #000;
  max-width: 788px;
  left: 0px;
  right: 0px;
  height: auto;
  margin: 0 auto;
  z-index: 100;
  top: 40px;
  border: 1px solid #a1ec00;
  border-radius: 5px;
  padding: 20px 30px;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Roboto Mono';
  color: #a1ec00;

  @media (max-width: 480px) {
    top: 0px;
    border-radius: 0px;
    overflow: scroll;
    height: 100%;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;
  }
`;

const Logo = styled.img`
  vertical-align: -12px;
`;

const Header = styled.header`
  margin-bottom: 12px;
`;

const List = styled.ol`
  margin: 12px 0;
`;

const ListItem = styled.li`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const Opportunities = styled.div`
  max-width: 480px;
  font-size: 13px;
  line-height: 20px;
  padding-right: 50px;
  flex: 1;

  a {
    color: #00ece5;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const RightBlock = styled.div`
  font-size: 13px;
  line-height: 20px;
  min-width: 200px;
  flex: 0;

  @media (max-width: 480px) {
    min-width: 100%;
  }

  h3 {
    font-size: 20px;
    line-height: 32px;
    margin-top: 0px;
    font-weight: normal;
  }
`;

const S = styled.strong`
  color: #ffef57;
`;

const Button = styled.button`
  background: #a1ec00;
  padding: 8px 18px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto Mono';
  border-radius: 5px;
  color: #000;
  margin-top: 22px;

  @media (max-width: 480px) {
    display: none;
  }
`;

const Mobile = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;

  @media (min-width: 480px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  margin: 20px 0;

  > a {
    margin-right: 10px;
  }
`;

const WelcomePopup = ({userWizards = [], power, drizzle, onButtonClick}) => {
  if (userWizards.length > 0) {
    userWizards.map(id =>
      drizzle.contracts.BasicTournament.methods.getWizard.cacheCall(id),
    );
  }

  return (
    <Wrapper>
      <Header>Hey you Cheezy</Header>
      <div>
        This is <Logo src={PlainLogo} alt="" />, the only on-chain guild that
        lets you win the Big Cheeze share without frustration of losing the
        battle 🐤.
      </div>
      <List>
        <ListItem>
          Get on board and join a unique community headed straight to the Big
          Cheeze.
        </ListItem>
        <ListItem>
          Lend the power of your wizards to the Great Battle Wizard chosen by
          the community.
        </ListItem>
        <ListItem>Get bank tokens in exchange of power.</ListItem>
        <ListItem>
          Get share of Big Cheese proportional to bank tokens.
        </ListItem>
      </List>
      <Footer>
        <Opportunities>
          Your opportunities: 
          <br />
          You hold <S>{userWizards.length}</S>{' '}
          <FormattedPlural
            value={userWizards.length}
            one="wizard"
            other="wizards"
          />{' '}
          with total power of <S>{formatPower(power)}</S>. Joining our guild (by
          donating your wizards power) you have a chance to receive not less
          than{' '}
          <S>
            {formattedEther(
              fromWei(
                ((power * 1000) / 2) *
                  (process.env.REACT_APP_UNP === 'true' ? 0.6 : 0.85),
                'ether',
              ),
            )}
          </S>{' '}
          ETH while keeping your wizard. You will receive ETH only if we win the
          Big Cheeze tournament accumulating enough energy – so, make sure to
          invite your friends. <br />
          If you don’t have a Cheeze Wizard but you are adventures enough to
          join CheezXpress – check{' '}
          <a href="https://opensea.io/assets/cheezewizard" target="_blank">
            {' '}
            available options on Opensea
          </a>
          .
        </Opportunities>
        <RightBlock>
          <h3>
            Make cheese <br />
            not war 🧀 ☮️
          </h3>
          Stay away from dangerous fights and enjoy the ride to the moon!
          <Button onClick={onButtonClick}>Get started</Button>
          <Mobile>
            We are not yet mobile optimized! Use desktop to join!
            <SocialLinks>
              <Telegram />
              <Twitter />
            </SocialLinks>
          </Mobile>
        </RightBlock>
      </Footer>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  userWizards: getCurrentUserWizards(state),
  power: getWizardsPower(state),
});

export default connect(mapStateToProps)(WelcomePopup);
