import React from 'react';
import WizPlaceholder from './wiz-placeholder.svg';
import styled from 'styled-components';

const StyledWizPlaceholder = styled.img`
  left: 296px;
  top: 121px;
  z-index: 2;
  position: absolute;
`;

export default React.forwardRef((props, ref) => (
  <StyledWizPlaceholder src={WizPlaceholder} width="72" height="21" ref={ref} />
));
