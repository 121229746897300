import {map, values, findKey, xor} from 'lodash';
import {createSelector} from 'reselect';
import {getContractAddressesForNetworkOrThrow} from '@chzwzrds/contracts-deploy/src/contract_addresses';
import {
  JOIN_SUCCESS,
  EXPAND_SECTION,
  GET_USER_WIZARDS_SUCCESS,
  GET_ROCKET_WIZARDS_SUCCESS,
  GET_APPLY_REQUESTS_SUCCESS,
  SELECT_WIZARD,
  SELECT_REQUEST,
  GOT_CONTRACT_VAR,
  SEND_CONTRACT_TX,
  UPDATE_WIZARD_STATUS,
  TX_ERROR,
} from '../actions';
import {getWizardsPlacements} from '../helpers';

const initialState = {
  wizardsOnBoard: [],
  positionedWizards: {
    cabin: [],
    sections: [],
    tail: [],
  },
  currentUserWizards: [],
  rocketWizards: [],
  applyRequests: [],
  selectedWizard: null,
  selectedRequests: [],
  tokenPrice: 0,
  wizards: {},
  claim: {},
};

function cheezxpress(state = initialState, action) {
  let wizardsOnBoard;
  let wizardId;
  let status;
  switch (action.type) {
    case JOIN_SUCCESS:
      wizardsOnBoard = [...state.wizardsOnBoard, ...action.payload].filter(
        value => value !== state.battleWizard,
      );
      return {
        ...state,
        wizardsOnBoard,
        positionedWizards: getWizardsPlacements({wizards: wizardsOnBoard}),
      };
    case EXPAND_SECTION: {
      return {
        ...state,
        positionedWizards: {
          ...state.positionedWizards,
          expandedSection:
            action.payload === state.positionedWizards.expandedSection
              ? undefined
              : action.payload,
        },
      };
    }
    case GET_USER_WIZARDS_SUCCESS:
      return {
        ...state,
        currentUserWizards: action.payload,
        selectedWizard: action.payload.length && action.payload[0],
      };
    case GET_ROCKET_WIZARDS_SUCCESS:
      wizardsOnBoard = [...state.wizardsOnBoard, ...action.payload].filter(
        value => value !== state.battleWizard,
      );
      return {
        ...state,
        wizardsOnBoard,
        positionedWizards: getWizardsPlacements({wizards: wizardsOnBoard}),
        rocketWizards: action.payload,
      };
    case GOT_CONTRACT_VAR:
      if (
        action.name === 'SwissCheezeBank' &&
        action.variable === 'battleWizard'
      ) {
        wizardsOnBoard = [
          ...state.wizardsOnBoard.filter(id => id !== action.value),
        ];
        return {
          ...state,
          wizardsOnBoard,
          positionedWizards: getWizardsPlacements({wizards: wizardsOnBoard}),
          battleWizard: action.value,
        };
      } else if (
        action.name === 'SwissCheezeBank' &&
        action.variable === 'getTokenAmount'
      ) {
        return {
          ...state,
          tokenPrice: action.value,
        };
      } else if (
        action.name === 'WizardGuild' &&
        action.variable === 'isApprovedOrOwner'
      ) {
        wizardId = action.args[1];
        return {
          ...state,
          wizards: {
            ...state.wizards,
            [wizardId]: {
              ...state.wizards[wizardId],
              approved: action.value,
            },
          },
        };
      } else if (
        action.name === 'SwissCheezeBank' &&
        (action.variable === 'waitingToJoin' || action.variable === 'joined')
      ) {
        wizardId = action.args[0];
        const joined =
          action.value === true ||
          (state.wizards[wizardId] && state.wizards[wizardId].status);
        return {
          ...state,
          wizards: {
            ...state.wizards,
            [wizardId]: {
              ...state.wizards[wizardId],
              status: joined ? 'joined' : null,
            },
          },
        };
      }
      return state;
    case SELECT_WIZARD:
      return {
        ...state,
        selectedWizard: action.payload,
      };
    case SELECT_REQUEST:
      return {
        ...state,
        selectedRequests: xor(state.selectedRequests, [action.payload]),
      };
    case GET_APPLY_REQUESTS_SUCCESS:
      return {
        ...state,
        applyRequests: action.payload,
      };
    case UPDATE_WIZARD_STATUS:
      ({wizardId, ...status} = action.payload);
      return {
        ...state,
        wizards: {
          ...state.wizards,
          [wizardId]: {
            ...state.wizards[wizardId],
            ...status,
          },
        },
      };
    case SEND_CONTRACT_TX:
      if (action.fnName === 'join') {
        wizardId = action.args[0];
        return {
          ...state,
          wizards: {
            ...state.wizards,
            [wizardId]: {
              ...state.wizards[wizardId],
              status: 'joining',
              stackId: action.stackId,
              stackTempKey: action.stackTempKey,
            },
          },
        };
      } else if (
        action.fnName === 'approve' &&
        action.contract.contractName === 'WizardGuild'
      ) {
        wizardId = action.args[1];
        return {
          ...state,
          wizards: {
            ...state.wizards,
            [wizardId]: {
              ...state.wizards[wizardId],
              status: 'approving',
              stackId: action.stackId,
              stackTempKey: action.stackTempKey,
            },
          },
        };
      } else if (
        action.fnName === 'approve' &&
        action.contract.contractName === 'Token'
      ) {
        wizardId = action.args[1];
        return {
          ...state,
          claim: {
            ...state.claim,
            stackId: action.stackId,
            stackTempKey: action.stackTempKey,
          },
        };
      }
      return state;
    case TX_ERROR:
      wizardId = findKey(
        state.wizards,
        w => w.stackTempKey === action.stackTempKey,
      );
      return {
        ...state,
        wizards: {
          ...state.wizards,
          [wizardId]: {
            ...state.wizards[wizardId],
            status: undefined,
            stackId: null,
            stackTempKey: null,
          },
        },
      };
    default:
      return state;
  }
}

export const getCurrentUserWizards = state =>
  state.cheezxpress && state.cheezxpress.currentUserWizards;
export const getRocketWizards = state => state.rocketWizards;
export const getWizardsDetails = state =>
  state.contracts &&
  state.contracts.BasicTournament &&
  state.contracts.BasicTournament.getWizard;
export const getRequests = state => map(state.applyRequests, 'wizardId');
export const getWizardsPower = createSelector(
  getCurrentUserWizards,
  getWizardsDetails,
  (userWizards = [], wizardsDetails) => {
    const vals = values(wizardsDetails);
    const totalPower = vals
      .filter(val => userWizards.includes(val.args[0]))
      .reduce(
        (acc, val) => acc + (val.value ? parseInt(val.value.power, 10) : 0),
        0,
      );
    return totalPower;
  },
);
export const getPoolTokenTotalSupply = state =>
  state.contracts.Token &&
  state.contracts.Token.totalSupply['0x0'] &&
  state.contracts.Token.totalSupply['0x0'].value;

export const getTokenSymbol = state =>
  state.contracts.Token &&
  state.contracts.Token.symbol['0x0'] &&
  state.contracts.Token.symbol['0x0'].value;

export const getWizardImagePath = state => {
  const baseUrl = 'https://storage.googleapis.com/cheeze-wizards-';
  const network = state.web3.networkId === 1 ? 'production' : 'staging';

  const contractAddresses = getContractAddressesForNetworkOrThrow(
    state.web3.networkId,
  );

  const wizardGuildAddress = contractAddresses.WIZARD_GUILD.toLowerCase();

  return wizardId =>
    `${baseUrl}${network}/original/${wizardGuildAddress}/${wizardId}.svg`;
};

export const getSelectedWizard = state => state.cheezxpress.selectedWizard;
export const getWizards = state => state.cheezxpress.wizards;
export const getTransactions = state => state.transactions;
export const getTransactionStack = state => state.transactionStack;
export const getClaim = state => state.claim;

export const getSelectedWizardStatus = createSelector(
  getSelectedWizard,
  getWizards,
  (selectedWizard, wizards) =>
    wizards[selectedWizard] && wizards[selectedWizard].status,
);

export const getSelectedWizardApprove = createSelector(
  getSelectedWizard,
  getWizards,
  (selectedWizard, wizards) =>
    wizards[selectedWizard] && wizards[selectedWizard].approved,
);

export const getSelectedWizardTimer = createSelector(
  getSelectedWizard,
  getWizards,
  (selectedWizard, wizards) =>
    wizards[selectedWizard] && wizards[selectedWizard].timer,
);

export const getSelectedWizardTransactionStatus = createSelector(
  getSelectedWizard,
  getWizards,
  getTransactionStack,
  getTransactions,
  (selectedWizard, wizards, transactionStack, transactions) => {
    try {
      let selectedWizardDetails = wizards[selectedWizard];
      let transactionHash = transactionStack[selectedWizardDetails.stackId];
      let transaction = transactions[transactionHash];
      return transaction.status;
    } catch (e) {
      return null;
    }
  },
);

export const getClaimTransactionStatus = createSelector(
  getClaim,
  getTransactionStack,
  getTransactions,
  (claim, transactionStack, transactions) => {
    try {
      // let selectedWizardDetails = wizards[selectedWizard];
      let transactionHash = transactionStack[claim.stackId];
      let transaction = transactions[transactionHash];
      return transaction.status;
    } catch (e) {
      return null;
    }
  },
);

export default cheezxpress;
