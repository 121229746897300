// update contract addresses
var networkToAddresses = {
  1: {
    WIZARD_PRESALE: '0x2F4Bdafb22bd92AA7b7552d270376dE8eDccbc1E',
    WIZARD_GUILD: '0x0d8c864DA1985525e0af0acBEEF6562881827bd5',
    INAUGURAL_GATEKEEPER: '0x68132EB4BfD84b2D6A23ec4fB1B106F5C8574F2D',
    THREE_AFFINITY_DUEL_RESOLVER: '0x1856A84019d982880607266526f13df232C15CFc',
    BASIC_TOURNAMENT: '0x6D6c3Beda37Ba83a4962746C43e3A20DB46399EC',
    // POOL_TOKEN: '0x48a14a9AA91dDd8d210155dd5B2aEbb6629C576c',
    POOL_TOKEN: '0xaa1f43ce3672e6bf9ca23186afc8241049400e4c',
    SWISS_CHEEZE_BANK: '0xd29F141d63AA7145598F18d9d9c924C214F4850b',
    WITHDRAWAL: '0xb1311D910B8a31DaF9269cA0d6e04EC6fc07e219',
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: '0xeFf719cf36947368C148Cf70f6F16AC53464C410',
    CLEAN_TOURNAMENT: '0xdbc7dd6dF6bE0A4DbDa8953a415d29BC299cF73C'
  },
  '1UNP': {
    WIZARD_PRESALE: '0x2F4Bdafb22bd92AA7b7552d270376dE8eDccbc1E',
    WIZARD_GUILD: '0x35B7838dd7507aDA69610397A85310AE0abD5034',
    INAUGURAL_GATEKEEPER: '0x673B537956a28e40aAA8D929Fd1B6688C1583dda',
    THREE_AFFINITY_DUEL_RESOLVER: '0x1856A84019d982880607266526f13df232C15CFc',
    BASIC_TOURNAMENT: '0xDd903896AaCC543Abeef0dEeA9B2a27496f762aD',
    POOL_TOKEN: '0x521871fE28Ab18D7564611e3021e139f37452A43',
    SWISS_CHEEZE_BANK: '0xed7392af2263d1404d631e0f920c67ebbc9cc7fc',
    WITHDRAWAL: '0x188244b65383c524882244cdc413AFb209Ee81B1',
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: '0xeFf719cf36947368C148Cf70f6F16AC53464C410',
    CLEAN_TOURNAMENT: '0xdbc7dd6dF6bE0A4DbDa8953a415d29BC299cF73C'
  },
  4: {
    WIZARD_PRESALE: '0xd44316ec2e3e61b312853beed738e830b650cbe8',
    WIZARD_GUILD: '0x0c5cf744d1284a0be2dd7a85d554173a1ed37041',
    INAUGURAL_GATEKEEPER: '0xd44316ec2e3e61b312853beed738e830b650cbe8',
    THREE_AFFINITY_DUEL_RESOLVER: '0xb789e4047f5df6cf5fdd035af44205092a275d33',
    BASIC_TOURNAMENT: '0x7ab67bdc5f8b30fe4e5c992547464d24f79be182',
    POOL_TOKEN: '0x165CCa88eda39C549557229d9ed3b05675E3911C',
    SWISS_CHEEZE_BANK: '0xcC2fD351bCC83201c98D56E5fc8E39E4aBb61a50',
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: '0xEC06a99a2F185e6e3E94D736933Db7cC61a83E94',
    CLEAN_TOURNAMENT: '0x62B214449F3Ec9B2d7b547DF2B2984c7a7dF5aA2'
  },
  50: {
    WIZARD_PRESALE: '0x609aCc8b356894a937fC58F3411F9528de96EcB1',
    WIZARD_GUILD: '0xCA9717a4A6e8009B3518648C9f3E7676255471A1',
    INAUGURAL_GATEKEEPER: '0x5D3AD3561A1235273cbCb4E82fCe63A0073d19be',
    THREE_AFFINITY_DUEL_RESOLVER: '0xB48E1B16829C7f5Bd62B76cb878A6Bb1c4625D7A',
    BASIC_TOURNAMENT: '0xe704967449b57b2382B7FA482718748c13C63190',
    POOL_TOKEN: '0x2eBb94Cc79D7D0F1195300aAf191d118F53292a8',
    SWISS_CHEEZE_BANK: '0xdD66C23e07b4D6925b6089b5Fe6fc9E62941aFE8',
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: '0xDFF540fE764855D3175DcfAe9d91AE8aEE5C6D6F',
    CLEAN_TOURNAMENT: '0x33DeF1aA867Be09809F3a01CE41d5eC1888846c9'
  },
  '50UNP': {
    WIZARD_PRESALE: '0x609aCc8b356894a937fC58F3411F9528de96EcB1',
    WIZARD_GUILD: '0xCA9717a4A6e8009B3518648C9f3E7676255471A1',
    INAUGURAL_GATEKEEPER: '0x5D3AD3561A1235273cbCb4E82fCe63A0073d19be',
    THREE_AFFINITY_DUEL_RESOLVER: '0xB48E1B16829C7f5Bd62B76cb878A6Bb1c4625D7A',
    BASIC_TOURNAMENT: '0xe704967449b57b2382B7FA482718748c13C63190',
    POOL_TOKEN: '0x2eBb94Cc79D7D0F1195300aAf191d118F53292a8',
    SWISS_CHEEZE_BANK: '0xdD66C23e07b4D6925b6089b5Fe6fc9E62941aFE8',
    // FIXME: This should be separate deploy in the snapshot
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: '0xDFF540fE764855D3175DcfAe9d91AE8aEE5C6D6F',
    CLEAN_TOURNAMENT: '0x33DeF1aA867Be09809F3a01CE41d5eC1888846c9'
  }
};
var networkNameToId = {
  mainnet: 1,
  'mainnet-fork': 1,
  rinkeby: 4,
  'rinkeby-fork': 4,
  development: 50
}; // TODO: fill in 0's

var networkToGenesis = {
  1: {
    WIZARD_PRESALE: 7779141,
    WIZARD_GUILD: 8723367,
    INAUGURAL_GATEKEEPER: 8723398,
    THREE_AFFINITY_DUEL_RESOLVER: 8723354,
    BASIC_TOURNAMENT: 8723430,
    POOL_TOKEN: 8879355,
    SWISS_CHEEZE_BANK: 8879365,
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: 8786978,
    CLEAN_TOURNAMENT: 8786981
  },
  '1UNP': {
    WIZARD_PRESALE: 7779141,
    WIZARD_GUILD: 8723367,
    INAUGURAL_GATEKEEPER: 8723398,
    THREE_AFFINITY_DUEL_RESOLVER: 8723354,
    BASIC_TOURNAMENT: 8723430,
    POOL_TOKEN: 8879355,
    SWISS_CHEEZE_BANK: 8879365,
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: 8786978,
    CLEAN_TOURNAMENT: 8786981
  },
  4: {
    WIZARD_PRESALE: 5205872,
    WIZARD_GUILD: 5205860,
    INAUGURAL_GATEKEEPER: 5205872,
    THREE_AFFINITY_DUEL_RESOLVER: 4917768,
    BASIC_TOURNAMENT: 5206257,
    POOL_TOKEN: 5110266,
    SWISS_CHEEZE_BANK: 5110276,
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: 5110276,
    CLEAN_TOURNAMENT: 5110278
  },
  50: {
    WIZARD_PRESALE: 0,
    WIZARD_GUILD: 0,
    INAUGURAL_GATEKEEPER: 0,
    THREE_AFFINITY_DUEL_RESOLVER: 0,
    BASIC_TOURNAMENT: 0,
    POOL_TOKEN: 0,
    SWISS_CHEEZE_BANK: 0,
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: 0,
    CLEAN_TOURNAMENT: 0
  },
  '50UNP': {
    WIZARD_PRESALE: 0,
    WIZARD_GUILD: 0,
    INAUGURAL_GATEKEEPER: 0,
    THREE_AFFINITY_DUEL_RESOLVER: 0,
    BASIC_TOURNAMENT: 0,
    POOL_TOKEN: 0,
    SWISS_CHEEZE_BANK: 0,
    CLEAN_THREE_AFFINITY_DUEL_RESOLVER: 0,
    CLEAN_TOURNAMENT: 0
  }
};
export function getContractAddressesForNetworkOrThrow(network) {
  var networkId;

  if (typeof network === 'string') {
    networkId = networkNameToId[network];
  } else {
    networkId = network;
  }

  if (process.env.REACT_APP_UNP === 'true') networkId += 'UNP';

  if (networkToAddresses[networkId] === undefined) {
    throw new Error("Unknown network id (".concat(networkId, "). No known 0x contracts have been deployed on this network."));
  }

  return networkToAddresses[networkId];
}
export function getContractGenesisForNetworkOrThrow(network) {
  var networkId;

  if (typeof network === 'string') {
    networkId = networkNameToId[network];
  } else {
    networkId = network;
  }

  if (process.env.REACT_APP_UNP === 'true') networkId += 'UNP';

  if (networkToGenesis[networkId] === undefined) {
    throw new Error("Unknown network id (".concat(networkId, "). No known 0x contracts have been deployed on this network."));
  }

  return networkToGenesis[networkId];
}