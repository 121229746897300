import React from 'react';
import styled from 'styled-components';
import {Telegram, Twitter} from './SocialLink';

const Wrapper = styled.div`
  position: fixed;
  bottom: 100px;
  right: 30px;
  line-height: 0px;

  a {
    display: block;

    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

const SocialLinks = () => (
  <Wrapper>
    <Telegram />
    <Twitter />
  </Wrapper>
);

export default SocialLinks;
