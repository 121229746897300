import {Drizzle, generateStore} from '@drizzle/store';
import drizzleOptions from '../drizzleOptions';
import cheezxpress from '../reducers';

const drizzleStore = generateStore({
  drizzleOptions,
  appReducers: {cheezxpress},
});
export const drizzle = new Drizzle(drizzleOptions, drizzleStore);

export default drizzleStore;
