import React from 'react';
import Dest from './dest.svg';
import styled from 'styled-components';

const StyledDestination = styled.img`
  z-index: 2;
  top: 163px;
  left: 153px;
  position: absolute;
`;

export default () => <StyledDestination src={Dest} width="210" height="18" />;
