import React from 'react';
import styled from 'styled-components';
import {ReactComponent as IconGetIn} from './icon-getin.svg';
import {ReactComponent as IconOnBoard} from './icon-onboard.svg';

/*
 * | Status     | Transaction status    | Button state    | Text          |
 * | ---------- | --------------------- | --------------- | ------------- |
 * | undefined  | undefined             | -               | Get on board  |
 * | approving  | undefined             | disabled        | Get on board  |
 * | approving  | pending               | disabled        | Approving...  |
 * | approving  | success               | -               | Get on board  |
 * | joining    | undefined             | disabled        | Get on board  |
 * | joining    | pending               | disabled        | Joining...    |
 * | joining    | success               | hidden          | -             |
 * | joined     | -                     | hidden          | -             |
 */

const Button = styled.button`
  border-radius: 4px;
  background-color: #a1ec00;
  border: none;
  padding: 0;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 900;
  font-size: 24px;
  outline: inherit;
  border-radius: 4px;
  background-color: #a1ec00;
  border: none;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 900;
  font-size: 24px;
  outline: inherit;
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 10px 6px 10px;

  span {
    display: inline-block;
    font-size: 20px;
  }
`;

const StyledGetInIcon = styled(IconGetIn)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  top: 4px;
`;

const StyledOnboardIcon = styled(IconOnBoard)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  top: 4px;
`;

const Success = styled.div`
  border-top: 3px #a1ec00 solid;
  font-family: 'Roboto Mono', sans-serif;
  position: absolute;
  bottom: 46px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 10px 6px 10px;
  font-weight: bold;

  span {
    display: inline-block;
    font-size: 20px;
  }
`;

const GetOnboardButton = ({onClick, status, transactionStatus}) => {
  let buttonText = 'Get on board';
  let disabled = false;

  if (status && transactionStatus !== 'success') {
    disabled = true;
  }

  if (
    (status === 'joining' && transactionStatus === 'success') ||
    status === 'joined'
  ) {
    return (
      <Success>
        <StyledOnboardIcon />
        <span>I'm on board</span>
      </Success>
    );
  }

  if (status === 'approving' && disabled) {
    buttonText = 'Approving...';
  }

  if (status === 'joining' && disabled) {
    buttonText = 'Joining...';
  }

  return (
    <Button onClick={onClick} disabled={disabled}>
      <StyledGetInIcon />
      <span>{buttonText}</span>
    </Button>
  );
};

export default GetOnboardButton;
