import React from 'react';
import {SECTION_OFFSET, TAIL_OFFSET} from '../constants';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  position: absolute;
  z-index: 3;
  width: 340px;
  height: 240px;
  left: ${({sectionsCount}) =>
    `${sectionsCount * SECTION_OFFSET + TAIL_OFFSET + 291 - 120}px`};
  top: 223px;
  fill: none;
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
`;

const GreenCorridor = React.forwardRef(({visible, sectionsCount}, ref) => (
  <StyledSvg
    viewBox="0 0 340 240"
    width="100"
    height="240"
    ref={ref}
    visible={visible}
    sectionsCount={sectionsCount}
  >
    <defs>
      <linearGradient id="linear-gradient" x1="0" y1="0" x2="0" y2="100%">
        <stop offset="0" stopColor="rgba(154,237,0,.4)" stopOpacity="0">
          <animate
            attributeName="stop-opacity"
            values="0.0; 0.75; 1.0; 0.5; 0.0; 0.0"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="0" stopColor="rgba(154,237,0,.6)" stopOpacity="0.5">
          <animate
            attributeName="stop-opacity"
            values="0.0; 0.25; 0.5; 1.0; 0.5; 0.25"
            dur="2s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="offset"
            values="0.0; 0.1;0.25; 0.5;0.75; 1.0"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="1" stopColor="rgba(154,237,0,.4)" stopOpacity="1">
          <animate
            attributeName="stop-opacity"
            values="0.25; 0.0; 0.0; 0.5; 1.0; 0.5"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
    <path
      id="polygon"
      fill="url(#linear-gradient)"
      d="M155.1,240 L135.3,220.2 L0,0 L100,0 L204.7,240 Z"
    />
  </StyledSvg>
));

export default GreenCorridor;
