// FIXME: Import abi directly instead of loading heavy artifact
import SwissCheezeBank from '@chzwzrds/contracts-deploy/abis/SwissCheezeBank.json';
import WizardGuild from '@chzwzrds/contracts-deploy/build/contracts/WizardGuild.json';
import BasicTournament from '@chzwzrds/contracts-deploy/build/contracts/BasicTournament.json';
import Token from '@chzwzrds/contracts-deploy/build/contracts/Token.json';
import Withdrawal from '@chzwzrds/contracts-deploy/abis/Withdrawal.json';
import {getContractAddressesForNetworkOrThrow} from '@chzwzrds/contracts-deploy/src/contract_addresses';

function contractDetails(abi, address) {
  return {
    abi,
    address,
  };
}

const networkContracts = contractAddresses => ({
  SwissCheezeBank: contractDetails(
    SwissCheezeBank.abi,
    contractAddresses.SWISS_CHEEZE_BANK,
  ),
  WizardGuild: contractDetails(WizardGuild.abi, contractAddresses.WIZARD_GUILD),
  BasicTournament: contractDetails(
    BasicTournament.abi,
    contractAddresses.BASIC_TOURNAMENT,
  ),
  Token: contractDetails(Token.abi, contractAddresses.POOL_TOKEN),
  Withdrawal: contractDetails(Withdrawal.abi, contractAddresses.WITHDRAWAL),
});
export const getContractConfig = ({contractName, web3, networkId}) => {
  const contracts = networkContracts(
    getContractAddressesForNetworkOrThrow(networkId),
  );
  const contract = contracts[contractName];

  if (contract) {
    const contractConfig = {
      contractName,
      web3Contract: new web3.eth.Contract(contract.abi, contract.address),
    };
    return contractConfig;
  }

  return undefined;
};
