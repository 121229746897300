import React from 'react';
import styled from 'styled-components';
import Loader from './loader.gif';

const StyledImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  display: block;
`;

const StyledLoader = () => <StyledImg src={Loader} />;

export default StyledLoader;
