import bn from 'bn.js';
import formatNumber from 'format-number';

export const generateRandomWizards = count => {
  const imgURLs = [
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1630-1567799898.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/3169-1567969247.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/3170-1567968060.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/3211-1567904915.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1705-1567943214.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1799-1567799883.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2231-1567812315.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2227-1567799860.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/4663-1567788650.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2772-1567781383.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2418-1567935782.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2427-1567935782.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2438-1567812437.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2442-1562996362.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2461-1565260453.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2471-1567743294.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2488-1567732208.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2494-1567245451.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/2523-1567722883.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1497-1567695659.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1446-1567690348.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1437-1567676823.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/1519-1567676707.svg',
    'https://storage.opensea.io/0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e/4435-1567781686.svg',
  ];
  const items = [];
  for (let i = 1; i <= count; i++) {
    items.push({
      src: imgURLs[Math.floor(Math.random() * imgURLs.length)],
      tokenId: i,
    });
  }

  return items;
};

export const getWizardsPlacements = ({
  wizards,
  wizardsInCabin = 11,
  wizardsInSection = 18,
  wizardsInTail = 15,
}) => {
  const _wizards = wizards.slice(0);
  const cabin = _wizards.splice(0, wizardsInCabin);
  let tail = [];
  let sections = [];
  // if (_wizards.length > wizardsInTail && _wizards.length < )
  if (_wizards.length <= wizardsInTail) {
    tail = _wizards;
  } else {
    while (Math.floor(_wizards.length / wizardsInTail) > 0) {
      const _wizardsInSection = _wizards.splice(0, wizardsInSection);
      sections.push(_wizardsInSection);
    }
    tail = _wizards;
  }
  return {
    cabin,
    sections,
    tail,
  };
};

export const formatPower = value => {
  const _bn = new bn(value);
  const divider = new bn(10);
  const power = new bn(12);
  return _bn.div(divider.pow(power)).toString();
};

export const getTransactionStatus = (drizzleState, stackId) => {
  if (drizzleState.transactionStack[stackId]) {
    const txHash = drizzleState.transactionStack[stackId];
    return (
      drizzleState.transactions[txHash] &&
      drizzleState.transactions[txHash].status
    );
  }
};
export const formattedEther = formatNumber({truncate: 2});
export const formattedEtherDet = formatNumber({truncate: 3});
export const formattedSlice = formatNumber({truncate: 6});
