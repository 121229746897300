import React from 'react';
import TwitterSvg from './twitter.svg';
import TelegramSvg from './telegram.svg';

const SocialLink = ({link, icon}) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <img src={icon} alt="" />
  </a>
);

export const Telegram = () => (
  <SocialLink link="https://t.me/cheezxpress" icon={TelegramSvg} />
);

export const Twitter = () => (
  <SocialLink link="https://twitter.com/cheezxpress" icon={TwitterSvg} />
);

export default SocialLink;
