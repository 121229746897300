var bn = require('bn.js');

var REVIVAL_PHASE = 'REVIVAL_PHASE';
var ASCENSION_WINDOW = 'ASCENSION_WINDOW';
var FIGHT_WINDOW = 'FIGHT_WINDOW';
module.exports = {
  REVIVAL_PHASE: REVIVAL_PHASE,
  ASCENSION_WINDOW: ASCENSION_WINDOW,
  FIGHT_WINDOW: FIGHT_WINDOW,
  STARTING_COST: new bn('70000000000000000'),
  // +
  COST_INCREMENT: 1000,
  // +
  EXCLUSIVE_COUNT: 10,
  SALE_DURATION: 2000,
  NEUTRAL_WIZARD_COST: new bn('70000000000000000'),
  // maybe this one should be 70 actually
  ELEMENTAL_WIZARD_COST: new bn('70000000000000000'),
  ELEMENTAL_WIZARD_INCREMENT: 1000,
  POWER_SCALE: 500,
  TENTH_BASIS_POINTS: 100000,
  ADMISSION_DURATION: 30,
  // > 0;
  // REVIVAL_DURATION % (ASCENSION_DURATION + FIGHT_DURATION + DUEL_TIMEOUT_DURATION + CULLING_DURATION) == 0;
  REVIVAL_DURATION: 170,
  // > 0;
  ASCENSION_DURATION: 30,
  // > 0
  FIGHT_DURATION: 100,
  // > 0; FIGHT_DURATION >= DUEL_TIMEOUT_DURATION * 2
  DUEL_TIMEOUT_DURATION: 20,
  // >= 20
  CULLING_DURATION: 20,
  // > 0; CULLING_DURATION >= DUEL_TIMEOUT_DURATION
  BLUE_MOLD_BASE_POWER: new bn('50000000000000'),
  // > 0;
  SESSIONS_BETWEEN_MOLD_DOUBLIN: 5 // > 0;

};