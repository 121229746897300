import {Component} from 'react';

class ShipContainer extends Component {
  state = {dataKey: null};
  componentDidMount() {
    const {drizzle} = this.props;
    const contract = drizzle.contracts.SwissCheezeBank;

    // get and save the key for the variable we are interested in
    const dataKey = contract.methods['battleWizard'].cacheCall();
    this.setState({dataKey});
  }
  render() {
    const {SwissCheezeBank} = this.props.drizzleState.contracts;
    const storedData =
      SwissCheezeBank && SwissCheezeBank.battleWizard[this.state.dataKey];

    return this.props.children({
      battleWizard: storedData && storedData.value,
    });
  }
}

export default ShipContainer;
