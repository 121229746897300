import React from 'react';
import Img from 'react-image';

const WizardImg = ({className, src, ...props}) => {
  return (
    <Img
      className={className}
      src={[
        src,
        src.replace(
          '0x0d8c864da1985525e0af0acbeef6562881827bd5',
          '0xec2203e38116f09e21bc27443e063b623b01345a',
        ),
        src.replace(
          '0x0d8c864da1985525e0af0acbeef6562881827bd5',
          '0x2f4bdafb22bd92aa7b7552d270376de8edccbc1e',
        ),
      ]}
      {...props}
    />
  );
};

export default WizardImg;
