import React, {Component} from 'react';
import {connect} from 'react-redux';
import ControlButton from '../components/ControlButton';

class EnterWizardsButton extends Component {
  state = {};
  handleButtonClick = () => {
    const {drizzle, drizzleState} = this.props;
    const stackId = drizzle.contracts.SwissCheezeBank.methods.enterWizards.cacheSend(
      drizzleState.cheezxpress.selectedRequests,
      {
        from: drizzleState.accounts[0],
      },
    );

    this.setState({
      stackId,
    });
  };
  render() {
    const {selectedRequests, drizzleState, blocksToFightWindow} = this.props;
    const {stackId} = this.state;
    let transactionStatus;
    if (drizzleState.transactionStack[stackId]) {
      const txHash = drizzleState.transactionStack[stackId];
      transactionStatus =
        drizzleState.transactions[txHash] &&
        drizzleState.transactions[txHash].status;
    }
    return (
      <ControlButton
        onClick={this.handleButtonClick}
        disabled={
          !selectedRequests.length ||
          transactionStatus === 'pending' ||
          blocksToFightWindow != 0
        }
      >
        {transactionStatus === 'pending' ? 'Entering...' : 'Enter wizards'}
      </ControlButton>
    );
  }
}

const mapStateToProps = state => ({
  selectedRequests: state.cheezxpress.selectedRequests,
});

export default connect(mapStateToProps)(EnterWizardsButton);
