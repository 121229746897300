import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {fromWei} from 'ethjs-unit';
import {getContractAddressesForNetworkOrThrow} from '@chzwzrds/contracts-deploy/src/contract_addresses';
import WizardCard from '../../components/WizardCard';
import GetOnboardButton from '../../components/GetOnboardButton';
import BubbleTailUp from './bubbletail-up.svg';
import {
  getCurrentUserWizards,
  getWizardImagePath,
  getTokenSymbol,
  getSelectedWizardStatus,
  getSelectedWizardTransactionStatus,
  getSelectedWizardTimer,
  getSelectedWizardApprove,
} from '../../reducers';
import {formattedEther, formattedEtherDet} from '../../helpers';
import {updateWizardStatus} from '../../actions';

import {getPoolTokenTotalSupply} from '../../reducers';

const StyledWarning = styled.div`
  position: absolute;
  font-weight: bold;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  bottom: 0;
`;

const StyledBubble = styled.div`
  position: absolute;
  font-weight: bold;
  top: 70px;
  left: 14px;
  font-family: 'Roboto Mono', sans-serif;
  border: #a1ec00 1px solid;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 10px 10px 12px;
  font-size: 14px;
  line-height: 18px;
  width: 400px;
`;

const StyledConfirmButton = styled.button`
  border: #a1ec00 1px solid;
  border-radius: 6px;
  background-color: black;
  font-size: 14px;
  color: #a1ec00;
  padding: 2px 6px 2px 6px;
  margin-top: 5px;
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 900;
`;

const StyledBubbleTail = styled.img`
  width: 22px;
  position: absolute;
  top: -18px;
  left: 355px;
`;

const Wrapper = styled.div`
  width: ${({fullwidth}) => (fullwidth ? '100%' : 'calc(50% - 15px)')};
`;

class SelectedWizardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: 'show',
      dapperTimers: {},
      balance: '0',
    };

    const {BASIC_TOURNAMENT} = getContractAddressesForNetworkOrThrow(
      this.props.drizzleState.web3.networkId,
    );

    props.drizzle.web3.eth.getBalance(BASIC_TOURNAMENT, (err, balance) => {
      if (err) console.log(err);
      else this.setState({balance});
    });
  }

  hideHelp = () => {
    this.setState({help: 'hide'});
  };

  showHelp = () => {
    this.setState({help: 'show'});
  };

  componentDidMount() {
    if (this.props.selectedWizard) {
      const contractAddresses = getContractAddressesForNetworkOrThrow(
        this.props.drizzleState.web3.networkId,
      );
      this.props.drizzle.contracts.WizardGuild.methods.isApprovedOrOwner.cacheCall(
        contractAddresses.SWISS_CHEEZE_BANK,
        this.props.selectedWizard,
      );
      this.props.drizzle.contracts.SwissCheezeBank.methods.waitingToJoin.cacheCall(
        this.props.selectedWizard,
      );
      this.props.drizzle.contracts.SwissCheezeBank.methods.joined.cacheCall(
        this.props.selectedWizard,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedWizard &&
      this.props.selectedWizard !== prevProps.selectedWizard
    ) {
      const contractAddresses = getContractAddressesForNetworkOrThrow(
        this.props.drizzleState.web3.networkId,
      );
      this.props.drizzle.contracts.WizardGuild.methods.isApprovedOrOwner.cacheCall(
        contractAddresses.SWISS_CHEEZE_BANK,
        this.props.selectedWizard,
      );
      this.props.drizzle.contracts.SwissCheezeBank.methods.waitingToJoin.cacheCall(
        this.props.selectedWizard,
      );
      this.props.drizzle.contracts.SwissCheezeBank.methods.joined.cacheCall(
        this.props.selectedWizard,
      );
    }
    if (
      prevProps.selectedWizardStatus === 'approving' &&
      prevProps.selectedWizardTransactionStatus === 'pending' &&
      this.props.selectedWizardTransactionStatus === 'success'
    ) {
      this.sendJoinTransaction();
    }
  }

  sendJoinTransaction = () => {
    const {drizzle, drizzleState, selectedWizard} = this.props;

    if (drizzle.web3.currentProvider.isDapper) {
      drizzle.contracts.SwissCheezeBank.methods
        .join(selectedWizard)
        .send({
          from: drizzleState.accounts[0],
        })
        .on('transactionHash', _hash => {
          const timer = setInterval(this.checkJoined, 15000, selectedWizard);
          this.setState({
            dapperTimers: {
              ...this.state.dapperTimers,
              [selectedWizard]: timer,
            },
          });
          this.props.updateWizardStatus({
            wizardId: selectedWizard,
            status: 'joining',
          });
        });
    } else {
      drizzle.contracts.SwissCheezeBank.methods.join.cacheSend(selectedWizard, {
        from: drizzleState.accounts[0],
      });
    }
  };

  handleGetInButtonClick = async () => {
    const {
      drizzle,
      drizzleState,
      selectedWizardApproved,
      selectedWizard,
    } = this.props;
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );
    // TODO: Finish loading state to get in
    // const isApproved =
    // drizzleState.contracts.WizardGuild &&
    // this.state[`approvedKey${this.props.selectedWizard}`] &&
    // drizzleState.contracts.WizardGuild.isApprovedOrOwner[
    // this.state[`approvedKey${this.props.selectedWizard}`]
    // ] &&
    // drizzleState.contracts.WizardGuild.isApprovedOrOwner[
    // this.state[`approvedKey${this.props.selectedWizard}`]
    // ].value;
    if (selectedWizardApproved) {
      this.sendJoinTransaction();
    } else {
      if (this.props.drizzle.web3.currentProvider.isDapper) {
        drizzle.contracts.WizardGuild.methods
          .approve(contractAddresses.SWISS_CHEEZE_BANK, selectedWizard)
          .send({
            from: drizzleState.accounts[0],
          })
          .on('transactionHash', _hash => {
            const timer = setInterval(
              this.checkApproval,
              15000,
              selectedWizard,
            );
            this.setState({
              dapperTimers: {
                ...this.state.dapperTimers,
                [selectedWizard]: timer,
              },
            });
            this.props.updateWizardStatus({
              wizardId: selectedWizard,
              status: 'approving',
            });
          });
      } else {
        drizzle.contracts.WizardGuild.methods.approve.cacheSend(
          contractAddresses.SWISS_CHEEZE_BANK,
          selectedWizard,
          {
            from: drizzleState.accounts[0],
          },
        );
      }
    }
  };

  checkApproval = wizardId => {
    const {drizzle, drizzleState} = this.props;
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );
    this.props.drizzle.contracts.WizardGuild.methods
      .isApprovedOrOwner(
        contractAddresses.SWISS_CHEEZE_BANK,
        wizardId,
        // this.state.approvingWizard,
      )
      .call()
      .then(approved => {
        if (approved) {
          const dapperTimers = {...this.state.dapperTimers};
          clearInterval(this.state.dapperTimers[wizardId]);
          delete dapperTimers[wizardId];
          this.setState({dapperTimers});
          this.sendJoinTransaction();
        }
      });
  };

  checkJoined = wizardId => {
    const {drizzle} = this.props;
    drizzle.contracts.SwissCheezeBank.methods
      .waitingToJoin(wizardId)
      .call()
      .then(waiting => {
        if (waiting) {
          const dapperTimers = {...this.state.dapperTimers};
          clearInterval(this.state.dapperTimers[wizardId]);
          delete dapperTimers[wizardId];
          this.setState({dapperTimers});
          this.props.updateWizardStatus({
            wizardId: wizardId,
            status: 'joined',
          });
        }
      });
  };

  renderWarning(power) {
    const {
      selectedWizardStatus,
      selectedWizardApproved,
      selectedWizardTransactionStatus,
    } = this.props;
    if (
      selectedWizardStatus === 'joined' ||
      (selectedWizardStatus === 'joining' &&
        selectedWizardTransactionStatus === 'success')
    ) {
      return (
        <StyledWarning>
          This wizard is already on his way to the moon.
          {power > 0 ? ' His power will be transferred soon' : null}
        </StyledWarning>
      );
    } else if (!selectedWizardApproved) {
      return (
        <StyledWarning>
          After pressing the button, you will need to sign two transactions.
          It's okay.
        </StyledWarning>
      );
    }
    return;
  }

  render() {
    const {
      currentUserWizards,
      selectedWizard,
      drizzle,
      drizzleState,
      tokenSymbol,
      getWizardImagePath,
      hasSeveralWizards,
      fullwidth,
      selectedWizardStatus,
      selectedWizardTransactionStatus,
      totalSupply,
    } = this.props;
    return (
      <Wrapper fullwidth={fullwidth}>
        <WizardCard
          single
          wizardId={currentUserWizards.find(
            wizardId => wizardId === selectedWizard,
          )}
          wizardImg={getWizardImagePath(
            currentUserWizards.find(wizardId => wizardId === selectedWizard),
          )}
          drizzle={drizzle}
          drizzleState={drizzleState}
          onStatusClick={this.showHelp}
          hasSeveralWizards={hasSeveralWizards}
        >
          {({price, power}) => (
            <>
              <GetOnboardButton
                status={selectedWizardStatus}
                transactionStatus={selectedWizardTransactionStatus}
                onClick={this.handleGetInButtonClick}
              />
              {this.renderWarning(power)}
              {this.state.help === 'show' ? (
                <StyledBubble>
                  You will receive {price} {tokenSymbol} for this wizard, which
                  gives you potential profit of not less than{' '}
                  {formattedEtherDet(
                    fromWei(
                      Math.floor(
                        ((power * 1000) / 2) *
                          (parseInt(this.state.balance) > 0
                            ? (parseInt(this.state.balance) * 2) /
                              Math.pow(10, 15) /
                              899072
                            : 0) *
                          (process.env.REACT_APP_UNP === 'true' ? 0.6 : 0.85),
                      ),
                      'ether',
                    ),
                  )}{' '}
                  ETH and up-to{' '}
                  {formattedEther(
                    fromWei(this.state.balance, 'ether') *
                      (totalSupply !== '0'
                        ? (price * Math.pow(10, 18)) /
                          (parseInt(totalSupply) + price * Math.pow(10, 18))
                        : 1),
                  )}{' '}
                  ETH
                  <br />
                  <StyledConfirmButton onClick={this.hideHelp}>
                    Got it
                  </StyledConfirmButton>
                  <StyledBubbleTail src={BubbleTailUp} />
                </StyledBubble>
              ) : null}
            </>
          )}
        </WizardCard>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  currentUserWizards: getCurrentUserWizards(state),
  selectedWizard: state.cheezxpress.selectedWizard,
  getWizardImagePath: getWizardImagePath(state),
  tokenSymbol: getTokenSymbol(state),
  selectedWizardStatus: getSelectedWizardStatus(state),
  selectedWizardTransactionStatus: getSelectedWizardTransactionStatus(state),
  selectedWizardTimer: getSelectedWizardTimer(state),
  selectedWizardApproved: getSelectedWizardApprove(state),
  totalSupply: getPoolTokenTotalSupply(state),
});

export default connect(
  mapStateToProps,
  {updateWizardStatus},
)(SelectedWizardContainer);
