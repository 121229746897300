import React from 'react';
import styled from 'styled-components';
import Moon from './moon.svg';

const w = 420;
const h = 420;

const StyledMoon = styled.img`
  z-index: 0;
  left: 90%;
  position: relative;
`;

export default () => <StyledMoon src={Moon} width={w} height={h} />;
