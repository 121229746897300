import React from 'react';
import Logo from './logo.svg';
import styled from 'styled-components';

const StyledLogo = styled.img`
  z-index: 2;
  top: 157px;
  left: 183px;
  position: absolute;
`;

export default () => <StyledLogo src={Logo} width="120" height="32" />;
