import React from 'react';
import styled from 'styled-components';
import {SECTION_OFFSET, TAIL_OFFSET} from '../../constants';
import ShipSectionContainer from '../../containers/ShipSectionContainer';
import WizardsGroup from '../WizardsGroup';
import WizardPlaceholder from '../WizardPlaceholder';
import OnboardLogo from '../OnboardLogo';
import {StyledWizard, StyledSectionContainer} from '../shared';
import {ReactComponent as CabinFrontSvg} from './cabin-front-right2left.svg';
import {ReactComponent as CabinBackSvg} from './cabin-back-right2left.svg';

const StyledCabinContainer = styled(StyledSectionContainer)`
  left: ${({sectionsLength}) =>
    sectionsLength * SECTION_OFFSET + TAIL_OFFSET}px;
`;

const StyledCapitan = styled(StyledWizard)`
  top: 15px;
  left: 370px;
  width: calc(1200px / 12);
  height: calc(1600px / 12);
`;

const CabinFront = styled(CabinFrontSvg)`
  position: absolute;
  z-index: 1;

  #_CabinFrontGradient3 {
    ${process.env.REACT_APP_UNP === 'true'
      ? `
  stop:nth-child(1) {
    stop-color: #946d20;
  }
  stop:nth-child(2) {
    stop-color: #89621a;
  }
  stop:nth-child(3) {
    stop-color: #7e5713;
  }

  `
      : `
  stop:nth-child(1) {
    stop-color: #809c66;
  }
  stop:nth-child(2) {
    stop-color: #78935e;
  }
  stop:nth-child(3) {
    stop-color: #708b57;
  }
  `}
  }
`;

const CabinBack = styled(CabinBackSvg)`
  position: absolute;
  z-index: 0;

  #_CabinBackLinear {
    ${process.env.REACT_APP_UNP === 'true'
      ? `
  stop:nth-child(1) {
    stop-color: #EFC458;
  }
  stop:nth-child(2) {
    stop-color: #76500E;
  }

  `
      : `
  stop:nth-child(1) {
    stop-color: #c1e1a1;
  }
  stop:nth-child(2) {
    stop-color: #6b8552;
  }
  `}
  }
`;

export default React.forwardRef(
  ({wizards, sectionsLength, index, battleWizard, id}, ref) => (
    <ShipSectionContainer index={index}>
      {({sectionOnClick, getWizardImagePath}) => (
        <StyledCabinContainer sectionsLength={sectionsLength}>
          <WizardsGroup wizards={wizards} width={4} index={index} />

          {battleWizard > 0 ? (
            <StyledCapitan src={getWizardImagePath(battleWizard)} />
          ) : null}
          <WizardPlaceholder ref={ref} />
          <CabinFront
            width="673"
            height="195"
            onClick={sectionOnClick}
            id={id}
          />
          <CabinBack width="673" height="195" />
          <OnboardLogo />
        </StyledCabinContainer>
      )}
    </ShipSectionContainer>
  ),
);
