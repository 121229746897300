import React from 'react';
import {ReactComponent as Fire} from './fire.svg';
import {ReactComponent as Water} from './water.svg';
import {ReactComponent as Wind} from './wind.svg';
import {ReactComponent as Neutral} from './neutral.svg';

const getIcon = affinity => {
  switch (affinity) {
    case 'fire':
      return <Fire />;
    case 'water':
      return <Water />;
    case 'wind':
      return <Wind />;
    default:
      return <Neutral />;
  }
};

const Affinity = ({type}) => getIcon(type);

export default Affinity;
