import React, {Component} from 'react';
import {connect} from 'react-redux';
import ControlButton from '../components/ControlButton';
import {getContractAddressesForNetworkOrThrow} from '@chzwzrds/contracts-deploy/src/contract_addresses';

class SendButton extends Component {
  state = {};
  handleButtonClick = () => {
    const {drizzle, drizzleState, selectedWizard} = this.props;
    // Declare this transaction to be observed. We'll receive the stackId for reference.
    const contractAddresses = getContractAddressesForNetworkOrThrow(
      drizzleState.web3.networkId,
    );
    const stackId = drizzle.contracts.WizardGuild.methods.transferFrom.cacheSend(
      drizzleState.accounts[0],
      contractAddresses.SWISS_CHEEZE_BANK,
      selectedWizard,
      {
        from: drizzleState.accounts[0],
      },
    );

    this.setState({
      stackId,
    });
  };
  render() {
    const {selectedWizard, drizzleState} = this.props;
    const {stackId} = this.state;
    let transactionStatus;
    if (drizzleState.transactionStack[stackId]) {
      const txHash = drizzleState.transactionStack[stackId];
      transactionStatus =
        drizzleState.transactions[txHash] &&
        drizzleState.transactions[txHash].status;
    }
    return (
      <ControlButton
        onClick={this.handleButtonClick}
        disabled={!selectedWizard || transactionStatus === 'pending'}
      >
        {transactionStatus === 'pending' ? 'Sending...' : 'Send'}
      </ControlButton>
    );
  }
}

const mapStateToProps = state => ({
  selectedWizard: state.cheezxpress.selectedWizard,
});

export default connect(mapStateToProps)(SendButton);
